/*
 * 업무구분: 고객
 * 화 면 명: MSPCM160M
 * 화면설명: 캠페인고객
 * 작 성 일: 2023.01.25
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="캠페인고객" :show-title="true" type="subpage" :action-type="!eventYn || checkCount > 1? actionSlotDisabled: actionSlot" @on-header-left-click="fn_HistoryBack" @on-scroll-bottom="fn_LoadingData(nextKey)"
     :topButton="true" :topButtonBottom="topButtonBottom">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pt34 pb30">
        <!-- 캠페인명 콤보 / 진행중인 캠페인 -->
        <ur-box-container alignH="start" componentid="" direction="row" class="row-box btn-question-wrap">
          <span class="label-title min50 mb0">캠페인</span>
          <msp-bottom-select ref="bottomSelect" :items="lv_CMCmpgnInfoVO" v-model="lv_CmpgnId" @input="fn_ChangeCmpgnCombo" underline class="ns-dropdown-sheet" placeholder="전체" bottom-title="캠페인"/>
          <mo-button color="normal" shape="solid" size="small" class="btn-question" @click="fn_OpenMSPCM163P()"/>
        </ur-box-container>
      </ur-box-container>
      <!-- 검색결과 -->
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start" direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData160M !== {}" @click="fn_OpenMSPCM161P">
            <span class="txt">{{pOrgData160M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData160M !== {}" @click="fn_OpenMSPCM161P">
            <span class="txt">{{pOrgData160M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData160M !== {}" @click="fn_OpenMSPCM161P">
            <span class="txt">{{pOrgData160M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData160M !== {}" @click="fn_OpenMSPCM161P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData160M.cnslt.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM161P">
            <span class="tit">고객</span>
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[1] !== ''" @click="fn_OpenMSPCM161P">
            <span class="tit">고객구분</span>
            <span class="txt">{{lv_ReturnStr[1]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[2] !== ''" @click="fn_OpenMSPCM161P">
            <span class="tit">캠페인유형</span>
            <span class="txt">{{lv_ReturnStr[2]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[3] !== ''" @click="fn_OpenMSPCM161P">
            <span class="tit">성과관리 기간포함</span>
            <span class="txt">{{lv_ReturnStr[3]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[4] !== ''" @click="fn_OpenMSPCM161P">
            <span class="tit">진척현황</span>
            <span class="txt">{{lv_ReturnStr[4]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <!--  검색영역 : 고객명 검색영역 //-->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="isEmptyView || checkDisabled" size="small" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{inqrCnt}}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong>명 선택</span>
            </div>
          </div>
          <mo-button componentid="" color="blue" shape="hyperlink" size="large" class="crTy-blue4 pl0 pr0 hauto min60" @click="fn_OpenMSPCM162P()">직접선정</mo-button>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="cMCmpgnCustInfoVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :disabled="checkDisabled" :ref="`chk${index}`"/>
              <div class="list-item__contents">
                <!-- 고객명 / 캠페인유형 -->
                <div class="list-item__contents__title">
                  <!-- 고객카드 없음 240129 추가 -->
                  <span class="name" :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" @click.stop="fn_CustNmClick(item)">
                    {{ item.custNm }}
                  </span>
                  <!-- 고객카드 없음 240129 추가 -->
                  <mo-badge class="badge-sample-badge lightblue sm crTy-blue3" :text="item.cmpgnTypCdNm" shape="status">{{item.cmpgnTypCdNm}}</mo-badge> 
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{item.dob2}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk1 underline" @click.stop="fn_OpenMSPCM164P(item)">{{item.cmpgnNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{item.adtnInfoCntnt01}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <!-- NoData 영역  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <!-- 대상제외 -->
      <mo-bottom-sheet ref="bottomSheetExclu" :close-btn="false" :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_cmpgnRmvClick" class="bgcolor-2 bd-radius-6 blue">대상제외</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 확인 메시지 -->
      <mo-bottom-sheet ref="bottomSheetMsg1"  class="ns-bottom-sheet">
        <div class="customer-info-txt txt-center">
          <p class="ment">선택한 고객을 캠페인 대상에서 제외하시겠습니까?</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_InitBottomSheet" name="취소">취소</mo-button>
              <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SubmitR" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 확인 메시지 -->
      <mo-bottom-sheet ref="bottomSheetMsg2"  class="ns-bottom-sheet">
        <div class="customer-info-txt txt-center">
          <p class="ment">선택한 고객이 캠페인 대상에서 제외되었습니다.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 개인정보 이용 및 활용 동의 팝업 -->
      <mo-bottom-sheet ref="bottomSheet_CustInfo" class="ns-bottom-sheet ns-style3">
        <template v-slot:title>고객카드 생성</template>
        <div class="customer-info-txt pt0">
          <strong class="name">{{ pObjmsg.custNm }}</strong>
          <p class="info">생년월일 
            <span class="birth">{{ $bizUtil.dateDashFormat(pObjmsg.dob) }}</span><em>|</em>
            <span>{{ pObjmsg.sxdsCdNm }}</span>
          </p>
          <p class="ment">
            등록된 고객이 아닙니다.<br>
            '고객카드 생성'버튼을 클릭하시면<br>
            고객카드가 생성됩니다.<br>
            (단, 필수컨설팅, 마케팅동의는 별도 수집 필요)
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_InitBottomSheet()">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Btn_I_OnClick()">고객카드 생성</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

      <!-- 초회 진입 로딩 표시 -->
      <div class="msp loading--dim msp-in-page" v-show="isReading">
        <div class="preloader-bubbling">
          <span id="bubbling_1"></span>
          <span id="bubbling_2"></span>
          <span id="bubbling_3"></span>
        </div>
        <div class="loading-text-box">
          <span class="title">{{loadingMsg}}</span>
          <span class="txt" v-html="loadingSubMsg" />
        </div>
      </div>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
      <div v-if="positionUp && cMCmpgnCustInfoVO.length > 0" class="ns-height120"></div>
      <div v-else-if="cMCmpgnCustInfoVO.length > 0" class="ns-height40"></div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPCM161P from '@/ui/cm/MSPCM161P' // 캠페인고객 상세조회
import MSPCM162P from '@/ui/cm/MSPCM162P' // 캠페인고객 직접선정
import MSPCM163P from '@/ui/cm/MSPCM163P' // 진행중인 캠페인
import MSPCM164P from '@/ui/cm/MSPCM164P' // 캠페인 고객정보
import MSPSA031P from '@/ui/sa/MSPSA031P' // 캠페인 고객 이벤트
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil.js'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM160M',
  // 현재 화면 ID
  screenId: 'MSPCM160M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM161P,
    MSPCM162P,
    MSPCM163P,
    MSPCM164P,
    MSPSA031P
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      toastMsg: '', // toast 문구

      // 체크 변수
      isSearched: false,        // 검색 실행 여부
      isInit: false,            // 검색 초기화 여부
      isMngr: false,            // 지점장 여부
      isOneOrMoreCheck: false,  // 전체선택
      positionUp: false,        // 대상제외 버튼
      isCustCardExist: true,    // 채널아이디 여부
      isFirst: true,            // 초회조회여부
      checkDisabled: false,     // 지점장 체크박스 선택 가능여부
      eventYn: false,           // 캠페인고객이벤트 여부

      // 팝업 연동
      pOrgData160M: {}, // 최근 조직 조회 데이터
      popup031: {}, // MSPSA031P 캠페인고객이벤트
      popup161: {}, // MSPCM161P 상세조회
      popup162: {}, // MSPCM162P 캠페인고객 직접선정
      popup163: {}, // MSPCM163P 진행중인 캠페인
      popup164: {}, // MSPCM164P 캠페인 상세내용
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      
      // 조회 변수
      // 캠페인고객 리스트 조회
      temp_cMCmpgnCustInfoVO: [], // 리스트VO 임시
      cMCmpgnCustInfoVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '',     // 컨설턴트 번호
      lv_inputParam: {    // 서비스호출 Obj
        hofOrgNo: '',     // 사업부조직번호
        dofOrgNo: '',     // 지역단조직번호
        fofOrgNo: '',     // 지점조직번호
        cnsltNo: '',      // 컨설턴트번호
        resuMngPrdIcluYn: 'X',  // 성과관리기간포함여부
        cmpgnId: '',            // 캠페인ID
        cmpgnTypCd: '',         // 캠페인유형코드
        chnlCustScCd: '',       // 채널고객구분코드
        cmpgnProgPnstaCd: '',   // 캠페인진척현황코드
        custNm: '',             // 고객명
      },
      inqrCnt: 0, // 총 조회건수
      msgCntnt: '', // 서비스 호출결과 메시지
      // 페이징
      nextKey: 0,
      stndKeyVal: '',
      // 캠페인 콤보 세팅
      lv_CmpgnId: '', // 캠페인ID
      lv_CMCmpgnInfoVO : [], // 캠페인정보VO
      // 캠페인 대상 제외
      lv_inputParamR: {
        cMCmpgnCustInfoVO: []
      },
      // 서비스호출 파라미터(리드상태변경)
      lv_inputParamS: {
        cMCmpgnCustInfoVO: []
      },
      // 고객카드 생성 변수
      pObjmsg: { // 고객카드 생성 Obj
        cmpgnId: '',        // 캠페인ID(EXTERNAL_ID)
        cmpgnTypCd: '',     // 캠페인유형코드(QUAL_LEVEL_MAN)
        chnlCustId: '',     // 채널고객ID(ZA_AGT_FILE_ID)
        guid: '',           // GUID(GUID)
        cmpgnStatCd: '',    // 캠페인상태코드(ESTAT)
        custId: '',         // 고객ID(PARTNER)
        cnsltNo: '',        // 컨설턴트번호(ZA_CHNL_ID)
        dob: '',            // 생년월일
        custNm: '',         // 고객명
        sxdsCdNm: '',       // 성별
      },
      // 캠페인 이벤트
      lv_EvtList: [],
      t_CmpgnEvtIdList: [],

      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.'
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.cMCmpgnCustInfoVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cMCmpgnCustInfoVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData160M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cMCmpgnCustInfoVO.length && this.cMCmpgnCustInfoVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    let lv_Vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 상단탭
    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_OpenCmpEvt">캠페인이벤트</mo-button><mo-icon type="picto" @click="fn_Search">search</mo-icon></div>',
        methods: {
          fn_OpenCmpEvt () {
            /**
             * 구사랑On 연계
             */
            // lv_Vm.fn_OpenMSPSA031P()
            lv_Vm.fn_OpenTSSSA033M()
          },
          fn_Search () {
            lv_Vm.fn_OpenMSPCM161P()
          }
        }
      }
    }
    this.actionSlotDisabled = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-button shape="border" class="ns-btn-round btn-title-style-1" disabled>캠페인이벤트</mo-button><mo-icon type="picto" @click="fn_Search">search</mo-icon></div>',
        methods: {
          fn_Search () {
            lv_Vm.fn_OpenMSPCM161P()
          }
        }
      }
    }

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호
    this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호

    // 이벤트ID 조회    
    this.fn_selEventId()

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
      // 첫 조회 실행하지 않으면서 최초진입 false
      this.isFirst = false
    } else {
      this.fn_Submit() // 임직원 아닐때만 최초진입 시 조회로직 실행
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    this.fn_InitBottomSheet()
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData160M = {}  // 조직 조회 데이터
          this.isSearched = false // 검색이력
          this.isInit = true
          this.isFirst = true
          this.fn_InitBottomSheet() // 바텀시트
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_ChangeCmpgnCombo
     * 설명       : 캠페인명 콤보 입력 이벤트
     ******************************************************************************/
    fn_ChangeCmpgnCombo () {
      let lv_Vm = this
      
      // 바텀시트 초기화
      lv_Vm.fn_InitBottomSheet()
      // 캠페인명 세팅
      lv_Vm.lv_inputParam.cmpgnId = lv_Vm.lv_CmpgnId
      // 검색이력 저장
      lv_Vm.isSearched = true
      // 캠페인고객 리스트 재조회
      lv_Vm.fn_Submit()
    },
    
    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetExclu !== undefined) {
          lv_Vm.positionUp = false
          lv_Vm.$refs.bottomSheetExclu.close()
        }
        if (lv_Vm.$refs.bottomSheetMsg1 !== undefined) {
          lv_Vm.$refs.bottomSheetMsg1.close()
        }
        if (lv_Vm.$refs.bottomSheetMsg2 !== undefined) {
          lv_Vm.$refs.bottomSheetMsg2.close()
        }
        if (lv_Vm.$refs.bottomSheet_CustInfo !== undefined) {
          lv_Vm.$refs.bottomSheet_CustInfo.close()
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_selEventId
    * 설명       : 이벤트ID 조회
    *
   ******************************************************************************/
    fn_selEventId () {
      
      const trnstId = 'txTSSSA31S4' // F1CRM0181
      const auth = 'S'
      var lv_Vm = this
      let pParams = {}
      this.isLoading = true      

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body.prgEvtList !== null && response.body.prgEvtList.length > 0) {
            let t_PrgEvtList = response.body.prgEvtList

            if (t_PrgEvtList.length > 0) {
              t_PrgEvtList.forEach((item) => {
                lv_Vm.lv_EvtList.push(item)
              })
            }
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (!_.isEmpty(this.pOrgData160M) && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData160M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      let lv_Vm = this

      lv_Vm.stndKeyVal = ''
      lv_Vm.inqrCnt = 0
      lv_Vm.cMCmpgnCustInfoVO = [] // 인피니트 바인딩 데이터
      lv_Vm.isEmptyView = false // 데이터가 없습니다 visible 여부
      lv_Vm.fn_DataSet() // 조회 파라미터 세팅

      if (lv_Vm.isFirst) {
        //초회 진입 시 다른 progress 타입 표시
        window.vue.getStore('progress').dispatch('NONE')
        this.fn_SetProgressMsgPop(true)

        lv_Vm.fn_selCmpgnInitVal()
      } else {        
        lv_Vm.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      let lv_Vm = this
      
      // 초회 상세조회조건
      if (lv_Vm.isFirst) {
        lv_Vm.pOrgData160M = {}
        lv_Vm.lv_inputParam.dofOrgNo = lv_Vm.userInfo.onpstDofNo // 지역단조직번호
        lv_Vm.lv_inputParam.fofOrgNo = lv_Vm.userInfo.onpstFofOrgNo // 지점조직번호
        lv_Vm.lv_inputParam.hofOrgNo = lv_Vm.userInfo.onpstHofOrgNo // 소속사업부조직번호
        lv_Vm.lv_CmpgnId = ''
        lv_Vm.lv_inputParam.cmpgnId = ''
        lv_Vm.lv_inputParam.cmpgnTypCd = ''
        lv_Vm.lv_inputParam.chnlCustScCd = ''
        lv_Vm.lv_inputParam.cmpgnProgPnstaCd = ''
        lv_Vm.lv_inputParam.custNm = ''
      }
      
      // 지점장 조회시 구분
      if (!_.isEmpty(lv_Vm.pOrgData160M) && lv_Vm.isMngr === true) {
        lv_Vm.lv_inputParam.cnsltNo  = lv_Vm.pOrgData160M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        lv_Vm.lv_inputParam.cnsltNo  = lv_Vm.lv_cnsltNo // 컨설턴트 번호
      }
    },
    
    /******************************************************************************
    * Function명 : fn_selCmpgnInitVal
    * 설명       : 캠페인유형 콤보 조회
    *
   ******************************************************************************/
    fn_selCmpgnInitVal () {
      const trnstId = 'txTSSCM53S1' // F1CRM0155, // F1CRM0156
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      let pParams = {}

      pParams.resuMngPrdIcluYn = 'X' // 성과관리기간포함여부 (CHAR1_ALV : 성과기간 포함여부 Default: ‘X’)
      pParams.cnsltNo = lv_Vm.lv_cnsltNo
      // 지점장 조회시 구분
      if (lv_Vm.pOrgData160M !== {} && lv_Vm.isMngr === true) {
        pParams.cnsltNo = lv_Vm.pOrgData160M.cnslt.key // 선택한 컨설턴트 번호
      } 

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            if (response.body.msgScCd === 'S' || response.body.msgScCd === null) {
              let responseList = response.body
              

              let tmpCMCmpgnInfoVO = responseList.cMCmpgnInfoVO // 캠페인정보VO
              let selectedObj = {}
              
              if (tmpCMCmpgnInfoVO !== null) {
                lv_Vm.lv_CMCmpgnInfoVO = []

                for (let j = 0; j < tmpCMCmpgnInfoVO.length; j++) {
                  let tmpObj = {}
                  tmpObj.value = tmpCMCmpgnInfoVO[j].cmpgnId
                  tmpObj.text = tmpCMCmpgnInfoVO[j].cmpgnNm
                  lv_Vm.lv_CMCmpgnInfoVO.push(tmpObj)

                  if (lv_Vm.lv_CmpgnId !== undefined) {
                    if (tmpObj.value === lv_Vm.lv_CmpgnId) {
                      selectedObj = tmpObj
                    }
                  }
                }
                if (selectedObj !== undefined && selectedObj.value !== undefined) {
                  lv_Vm.lv_CmpgnId = selectedObj.value
                } else {
                  if (lv_Vm.lv_CmpgnId === '') lv_Vm.lv_CmpgnId = lv_Vm.lv_CMCmpgnInfoVO[0].value // 캠페인명 초기값 셋팅 - '전체'
                }
              }              
              lv_Vm.fn_GetService()
            } else {
              confirm.dispatch('ADD', response.body.msgCntnt)
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(캠페인고객 리스트 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM53S2' // F1CRM0157
      const trnstId = 'txTSSCM48S4' // F1CRM0157
      const auth = 'S'
      let lv_Vm = this
      let confirm = this.getStore('confirm')

      let pParams = this.lv_inputParam

      this.isLoading = true
      this.nextKey = 0

      if (!this.isFirst) {
        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('SKEL_LIST')
      } else {
        this.isFirst = false
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            if (response.body.msgScCd === 'S' || response.body.msgScCd === null) {
              let responseList = response.body.cMCmpgnCustInfoVO
              lv_Vm.stndKeyVal = 'start' // nextkey 초기화
              lv_Vm.inqrCnt = response.body.inqrCnt // 조회건수

              for (let i in responseList) responseList[i].checked = false
              lv_Vm.temp_cMCmpgnCustInfoVO = responseList // tempVo에 결과값 바인딩

              // 캠페인 이벤트
              lv_Vm.eventYn = false
              lv_Vm.t_CmpgnEvtIdList = []
              if (lv_Vm.temp_cMCmpgnCustInfoVO.length > 0 && lv_Vm.lv_CmpgnId.trim() !== '') {
                lv_Vm.fn_selCmpgn(lv_Vm.temp_cMCmpgnCustInfoVO[0].cmpgnClsfKey)
              }

              lv_Vm.fn_LoadingData(lv_Vm.nextKey) // 조회 결과 바인딩 함수 호출 (자체 페이징 처리)

              if (lv_Vm.inqrCnt === 0) {
                lv_Vm.isEmptyView = true
              } 
              // 메시지 팝업 처리
              // if (lv_Vm.msgCntnt !== '') {
              //   lv_Vm.toastMsg = lv_Vm.msgCntnt
              //   lv_Vm.$refs.snackbar.show({ text: lv_Vm.toastMsg }) // 토스트 팝업 호출
              //   lv_Vm.msgCntnt = '' // 초기화
              // }
            } else {
              lv_Vm.cMCmpgnCustInfoVO = []
              lv_Vm.isEmptyView = true
            }
          } else {
            if (response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
              lv_Vm.cMCmpgnCustInfoVO = []
            } else {
              confirm.dispatch('ADD', response.msgComm.msgDesc)
            }
          }

          lv_Vm.isLoading = false
          lv_Vm.fn_SetProgressMsgPop(false)
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_selCmpgn
    * 설명       : 캠페인 상세 조회 IF-CM-3026(F1CRM0160)
    *
   ******************************************************************************/
    fn_selCmpgn (cmpgnClsfKey) {
      const trnstId = 'txTSSCM53S5' // F1CRM0170
      const auth = 'S'
      var lv_Vm = this

      lv_Vm.lv_inputParam.cmpgnClsfKey = cmpgnClsfKey // 캠페인 GUID (OBJKEY_A) / 캠페인ID (EXTERNAL_ID)

      let pParams = this.lv_inputParam

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            if (response.body.msgScCd === 'S' || response.body.msgScCd === null) {
              let responseList = response.body.cMCmpgnDtlVO
              for (var i in lv_Vm.lv_EvtList) {
                if (lv_Vm.lv_EvtList[i].mobslEvtId === responseList[0].cmpgnPlcNm) {
                  lv_Vm.t_CmpgnEvtIdList = [lv_Vm.lv_EvtList[i]]
                  lv_Vm.eventYn = true
                  break
                }
              }
            } else {
              confirm.dispatch('ADD', response.body.msgCntnt)
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // }
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 리스트 스크롤시 로딩 함수
    ******************************************************************************/
    fn_LoadingData (strIdx) {
      if (this.stndKeyVal === 'end') return

      let lv_Vm = this
      let endIdx = strIdx

      if (this.isOneOrMoreCheck) this.isOneOrMoreCheck = false  // 전체체크 해제

      if (strIdx + 19 < lv_Vm.inqrCnt) {
        endIdx = strIdx + 19
      } else {
        endIdx = parseInt(lv_Vm.inqrCnt) - 1
      }

      for (let i = strIdx; i <= endIdx; i++) {
        lv_Vm.cMCmpgnCustInfoVO.push(lv_Vm.temp_cMCmpgnCustInfoVO[i])

        // 생일
        if (this.cMCmpgnCustInfoVO[i].dob !== '00000000') {
          this.cMCmpgnCustInfoVO[i].dob2 = this.$pmUtil.getMaskingBirth(this.cMCmpgnCustInfoVO[i].dob)
        } else {
          this.cMCmpgnCustInfoVO[i].dob2 = ''
        }
        // 최근터치일
        if (this.cMCmpgnCustInfoVO[i].rntTuchYmd !== '00000000') {
          this.cMCmpgnCustInfoVO[i].rntTuchYmd2 = this.$commonUtil.dateDashFormat(this.cMCmpgnCustInfoVO[i].rntTuchYmd)
        } else {
          this.cMCmpgnCustInfoVO[i].rntTuchYmd2 = ''
        }
        // 시작일
        if (this.cMCmpgnCustInfoVO[i].strYmd !== '00000000') {
          this.cMCmpgnCustInfoVO[i].strYmd2 = this.$commonUtil.dateDashFormat(this.cMCmpgnCustInfoVO[i].strYmd)
        } else {
          this.cMCmpgnCustInfoVO[i].strYmd2 = ''
        }
        // 종료일
        if (this.cMCmpgnCustInfoVO[i].endYmd !== '00000000') {
          this.cMCmpgnCustInfoVO[i].endYmd2 = this.$commonUtil.dateDashFormat(this.cMCmpgnCustInfoVO[i].endYmd)
        } else {
          this.cMCmpgnCustInfoVO[i].endYmd2 = ''
        }
        // 진척현황 : 신계약>가설>컨설팅 순으로 해당날짜 있는 단계 return
        if (lv_Vm.cMCmpgnCustInfoVO[i].ncontYmd.trim() !== '') {
          lv_Vm.cMCmpgnCustInfoVO[i].cmpgnProgPnstaCdNm = '신계약'
        } else if (lv_Vm.cMCmpgnCustInfoVO[i].entplYmd.trim() !== '') {
          lv_Vm.cMCmpgnCustInfoVO[i].cmpgnProgPnstaCdNm = '가설'
        } else if (lv_Vm.cMCmpgnCustInfoVO[i].cnstgYmd.trim() !== '') {
          lv_Vm.cMCmpgnCustInfoVO[i].cmpgnProgPnstaCdNm = '컨설팅'
        } else {
          lv_Vm.cMCmpgnCustInfoVO[i].cmpgnProgPnstaCdNm = ''
        }
      }

      if (endIdx + 1 < parseInt(lv_Vm.inqrCnt)) {
        lv_Vm.nextKey = endIdx + 1
        lv_Vm.stndKeyVal = 'more'
      } else {
        lv_Vm.nextKey = 0
        this.stndKeyVal = 'end'
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.cMCmpgnCustInfoVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.cMCmpgnCustInfoVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.cMCmpgnCustInfoVO[n].checked = true
            } else {
              lv_Vm.cMCmpgnCustInfoVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다.
     ******************************************************************************/
    fn_CheckSelectedItemCount (custInfo) {
      let lv_Vm = this

      // 체크된 값 채널아이디 여부 확인
      if (lv_Vm.cMCmpgnCustInfoVO.filter(item => (item.checked === true && item.chnlCustId.trim() === '')).length === 0) {
        lv_Vm.isCustCardExist = true
      } else {
        lv_Vm.isCustCardExist = false
      }

      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
      if (lv_Vm.checkCount > 0) {
        lv_Vm.positionUp = true
        lv_Vm.$refs.bottomSheetExclu.open()  // 대상제외 버튼바텀시트
      } else {
        lv_Vm.fn_InitBottomSheet()
      }
    },

    /******************************************************************************
    * Function명 : fn_cmpgnRmvClick
    * 설명       : 캠페인제외버튼 클릭 핸들러
    ******************************************************************************/
    fn_cmpgnRmvClick () {
      let lv_Vm = this
      let dateToday = Number(moment(new Date()).format('YYYYMMDD'))

      for (let i = 0; i < lv_Vm.selectItems.length; i++) {
        let sfinishDate = lv_Vm.selectItems[i].endYmd
        if (Number(sfinishDate) < Number(dateToday)) {
          this.getStore('confirm').dispatch('ADD', this.$t('cm')['ECMC057']) // 캠페인 종료일이 지난 데이터는 진행할 수 없습니다.
          lv_Vm.selectItems[i].checked = false

          return
        }
      }
      
      // 캠페인 제외 확인 메시지 바텀시트
      lv_Vm.$refs.bottomSheetMsg1.open()
    },

    /******************************************************************************
     * Function명 : fn_SubmitR
     * 설명       : 대상제외 선택 이벤트
     ******************************************************************************/
    fn_SubmitR () {
      // 캠페인대상 제외 데이터 세팅
      this.fn_DataSetR()
      // 캠페인대상 제외 서비스
      this.fn_delCmpgnObjExclu()
    },

    /******************************************************************************
      * Function명 : fn_DataSetR
      * 설명       : 캠페인 대상 제외 처리 전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetR () {
      this.lv_inputParamR = {
        cMCmpgnCustInfoVO: []
      }
      for (let i = 0; i < this.checkCount; i++) {
        let saveObj = {}

        saveObj.guid = this.selectItems[i].guid // GUID
        saveObj.incluYn = 'X' // 포함여부 (REJECTED)
        saveObj.cmpgnStatCd = this.selectItems[i].cmpgnStatCd // 캠페인상태코드 (ESTAT)
        saveObj.zzPriority = this.selectItems[i].zzPriority // 우선순위 (IMPORTANCE)
        saveObj.cmpgnId = this.selectItems[i].cmpgnId // 캠페인ID (EXTERNAL_ID)
        saveObj.chnlCustId = this.selectItems[i].chnlCustId // 채널고객ID (ZA_AGT_FILE_ID)
        saveObj.cmpgnObjId = this.selectItems[i].cmpgnObjId // 캠페인대상ID (OBJECT_ID)
        saveObj.cmpgnTypCd = this.selectItems[i].cmpgnTypCd // 캠페인유형코드 (QUAL_LEVEL_MAN)
        saveObj.cmpgnTyp = this.selectItems[i].cmpgnTyp // 캠페인유형 (CAMP_TYPE)
        saveObj.cmpgnGoalCntnt = this.selectItems[i].cmpgnGoalCntnt // 캠페인목표내용 (OBJECTIVE)
        saveObj.cmpgnPrfmcMthCd = this.selectItems[i].cmpgnPrfmcMthCd // 캠페인수행방법코드 (TACTICS)

        this.lv_inputParamR.cMCmpgnCustInfoVO.push(saveObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_delCmpgnObjExclu
     * 설명       : 캠페인 대상 제외 처리 (IF-CM-3024 F1CRM0158)
     *
     ******************************************************************************/
    fn_delCmpgnObjExclu () {
      const trnstId = 'txTSSCM53D1' // F1CRM0158
      const auth = 'D'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      this.isLoading = true

      let pParams = this.lv_inputParamR

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body
            if (responseList.msgScCd === 'S') { // 성공메시지
              lv_Vm.$refs.bottomSheetMsg2.open()
            } 
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false

          // 캠페인 고객목록 재조회
          lv_Vm.fn_Submit()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // }
    },

    /******************************************************************************
    * Function명 : fn_CustNmClick
    * 설명       : 고객명 눌렀을 때 수행되는 함수
    ******************************************************************************/
    fn_CustNmClick (custInfo) {
      if (custInfo.chnlCustId.trim() === '') {
        // 채널고객ID가 없을 경우 공통이 아닌 화면별 고객등록동의 팝업 표시
        this.fn_OpenInsCustInfo(custInfo)
      } else {
        // 2020.06.09
        if (custInfo.cmpgnStatCd === 'E0001') { // ESTAE 값이 E0001 일때만 0158 호출 요청 (E0002로 업데이트)
          this.fn_DataSetS(custInfo)
          this.fn_updCmpgnObjExclu(custInfo)
        }
        // 네임UI 오픈
        this.fn_OpenCustInfoBs(custInfo)
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenInsCustInfo
     * 설명       : 고객카드 생성 팝업
     ******************************************************************************/
    fn_OpenInsCustInfo (custInfo) {
      this.pObjmsg = {
        cmpgnId: custInfo.cmpgnId, // 캠페인ID(EXTERNAL_ID)
        cmpgnTypCd: custInfo.cmpgnTypCd, // 캠페인유형코드(QUAL_LEVEL_MAN)
        chnlCustId: custInfo.chnlCustId, // 채널고객ID(ZA_AGT_FILE_ID)
        guid: custInfo.guid, // GUID(GUID)
        cmpgnStatCd: custInfo.cmpgnStatCd, // 캠페인상태코드(ESTAT)
        custId: custInfo.custId, // 고객ID(PARTNER)
        cnsltNo: this.lv_cnsltNo, // 컨설턴트번호(ZA_CHNL_ID)
        dob: custInfo.dob, // 생년월일
        custNm: custInfo.custNm, // 고객명
        sxdsCdNm: custInfo.sxdsCdNm, // 성별
      }

      // 고객카드생성 바텀시트 오픈
      this.$refs.bottomSheet_CustInfo.open()
    },

    /******************************************************************************
     * Function명 : fn_Btn_I_OnClick
     * 설명       : 고객카드생성 확인 버튼
     ******************************************************************************/
    fn_Btn_I_OnClick () {
      // 지점장 권한일 경우 고객카드생성 불가
      if (this.isMngr !== true) {
        this.fn_insCmpgnCustInfo()
      }
    },

    /******************************************************************************
    * Function명 : fn_insCmpgnCustInfo
    * 설명       : 캠페인 신규고객카드 생성 (F1CRM0159)
    *
   ******************************************************************************/
    fn_insCmpgnCustInfo () {
      const trnstId = 'txTSSCM53I2'
      const auth = 'I'
      let lv_Vm = this
      let confirm = this.getStore('confirm')
      let pParams = this.pObjmsg

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
        // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body

            if (responseList.msgScCd === 'S') { // 성공메시지
              lv_Vm.msgCntnt = responseList.msgCntnt
              // 바텀시트 초기화
              lv_Vm.fn_InitBottomSheet()
              // 재조회
              lv_Vm.fn_Submit()
              // 네임UI 오픈
              let custInfo = {
                chnlCustId: responseList.cMCmpgnCustInfoVO[0].chnlCustId,
                custNm: ''
              }
              lv_Vm.fn_OpenCustInfoBs(custInfo)
            } else { // 실패메시지
              lv_Vm.msgCntnt = responseList.msgCntnt
              // 메시지 팝업 처리
              if (lv_Vm.msgCntnt !== '') {
                confirm.dispatch('ADD', lv_Vm.msgCntnt)
                lv_Vm.msgCntnt = '' // 초기화
              }
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_DataSetS
      * 설명       : 캠페인고객 리드상태변경 처리 전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetS (custInfo) {
      this.lv_inputParamS = {
        cMCmpgnCustInfoVO: []
      }
      let saveObj = {}
      saveObj.guid = custInfo.guid // GUID
      saveObj.incluYn = 'X' // 포함여부 (REJECTED)
      saveObj.cmpgnStatCd = custInfo.cmpgnStatCd // 캠페인상태코드 (ESTAT)
      saveObj.zzPriority = custInfo.zzPriority // 우선순위 (IMPORTANCE)
      saveObj.cmpgnId = custInfo.cmpgnId // 캠페인ID (EXTERNAL_ID)
      saveObj.chnlCustId = custInfo.chnlCustId // 채널고객ID (ZA_AGT_FILE_ID)
      saveObj.cmpgnObjId = custInfo.cmpgnObjId // 캠페인대상ID (OBJECT_ID)
      saveObj.cmpgnTypCd = custInfo.cmpgnTypCd // 캠페인유형코드 (QUAL_LEVEL_MAN)
      saveObj.cmpgnTyp = custInfo.cmpgnTyp // 캠페인유형 (CAMP_TYPE)
      saveObj.cmpgnGoalCntnt = custInfo.cmpgnGoalCntnt // 캠페인목표내용 (OBJECTIVE)
      saveObj.cmpgnPrfmcMthCd = custInfo.cmpgnPrfmcMthCd // 캠페인수행방법코드 (TACTICS)

      this.lv_inputParamS.cMCmpgnCustInfoVO.push(saveObj)
    },

    /******************************************************************************
    * Function명 : fn_updCmpgnObjExclu
    * 설명       : 캠페인고객 리드상태변경
    *
   ******************************************************************************/
    fn_updCmpgnObjExclu (custInfo) {
      const trnstId = 'txTSSCM53D2' // F1CRM0158
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      this.isLoading = true

      let pParams = this.lv_inputParamS
      let sFailName = ''

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body

            if (responseList.msgScCd === 'S') { // 성공메시지
              lv_Vm.msgCntnt = responseList.msgCntnt
            } else { // 고객별 실패메시지
              if (responseList.cMCmpgnCustInfoVO.length !== 0) {
                for (let i = 0; i < responseList.cMCmpgnCustInfoVO.length; i++) {
                  if (sFailName === '') {
                    sFailName = responseList.cMCmpgnCustInfoVO[i].msgCntnt
                  } else {
                    sFailName = sFailName + ' : ' + responseList.cMCmpgnCustInfoVO[i].msgCntnt
                  }
                }
                lv_Vm.msgCntnt = sFailName
              }
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPSA031P
     * 설명       : 캠페인고객 이벤트 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPSA031P () {
      // 채널고객ID가 없을 경우 고객카드바로생성 표시
      if (this.selectItems[0].chnlCustId.trim() === '') {
        this.fn_OpenInsCustInfo(this.selectItems[0])
        return
      } 

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('NONE')

      this.popup031 = this.$bottomModal.open(MSPSA031P, {
        properties: {
          pCustInfo: this.selectItems[0],
          evtList: this.t_CmpgnEvtIdList,
          mobslEvtId: this.t_CmpgnEvtIdList[0].mobslEvtId
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.popup031)
          },
          goMspCm050m: (param) => {
            this.$bottomModal.close(this.popup031)
            this.$router.push({name: 'MSPCM050M', params: {custNm: param, srnId: this.$options.screenId}})
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenTSSSA033M
     * 설명       : 캠페인고객 이벤트 팝업 오픈 (구사랑On 연계)
     * params: {
        custNm:   ,// 고객명
        chnlCustId: , // 채널고객ID
        cmpgnEvtIdList: // 캠페인이벤트ID 리스트
      }
     ******************************************************************************/
    async fn_OpenTSSSA033M () {      
      // 채널고객ID가 없을 경우 고객카드바로생성 표시
      if (this.selectItems[0].chnlCustId.trim() === '') {
        this.fn_OpenInsCustInfo(this.selectItems[0])
        return
      }

      if (this.t_CmpgnEvtIdList.length === 0) {
        window.vue.getStore('confirm').dispatch('ADD', '고객을 선택해주세요.')
        return      
      }

      if (this.$bizUtil.isEmpty(this.t_CmpgnEvtIdList[0].mobslEvtId) || this.t_CmpgnEvtIdList[0].mobslEvtId === '') {
        window.vue.getStore('confirm').dispatch('ADD', '이벤트 정보가 없습니다.')
        return      
      }

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('NONE')
      
      let deviceType = await PSCommUtil.fn_GetAppDeviceEnv()
      if (navigator.userAgent.indexOf('OIC-Agent iOS') > -1) {
        window.vue.getInstance('vue').$addSnackbar('아이폰은 서비스 준비중으로, 안드로이드 기기에서 이용해주세요.')
        return false
      } else if (deviceType === 'Phone' && navigator.userAgent.indexOf('OIC-Agent Android') > -1) {
        window.vue.getInstance('vue').$addSnackbar('갤럭시 S24에서는 지원하지 않습니다. 태블릿으로 이용해주세요.')
        return false
      }

      try {
        let tmpSendIntentObj = {}
        let tmpCmpnIdList = []
        let tmpCmpnId = {
          mobslEvtId: this.t_CmpgnEvtIdList[0].mobslEvtId
        }
        tmpCmpnIdList.push(tmpCmpnId)
        let paramObj = {
          custNm: this.selectItems[0].custNm,
          chnlCustId: this.selectItems[0].chnlCustId,
          cmpgnEvtIdList: tmpCmpnIdList
        }

        tmpSendIntentObj.userEno = window.vue.getStore('userInfo').getters.getUserInfo.userId
        tmpSendIntentObj.srnId = 'TSSSA033M'
        tmpSendIntentObj.param = JSON.stringify(paramObj)        

        window.fdpbridge.exec('intentTssAccessPlugin', tmpSendIntentObj, () => {}, (e) => {
          window.vue.getInstance('vue').$addSnackbar(`'삼성생명 앱스토어'에서 '단체/융자_사랑On' 앱을 설치 후 다시 시도해주세요.`)
        })
      } catch (e) {
        console.log('구사랑On 연계 오류 : ', e.name , e.message)
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM161P
     * 설명       : 상세조회조건 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM161P () {
      let lv_Vm = this

      this.popup161 = this.$bottomModal.open(MSPCM161P, {
        properties: {
          pIsMngr: this.isMngr, // 관리자여부
          pOrgData: this.pOrgData160M, // 조직정보
          pCustNm: this.lv_inputParam.custNm, // 고객명
          pCmpgnTypCd: this.lv_inputParam.cmpgnTypCd, // 캠페인유형코드
          pResuMngPrdIcluYn: this.lv_inputParam.resuMngPrdIcluYn, // 성과관리기간포함여부
          pCmpgnId: this.lv_inputParam.cmpgnId, // 캠페인ID
          pChnlCustScCd: this.lv_inputParam.chnlCustScCd, // 채널고객구분코드
          pCmpgnProgPnstaCd: this.lv_inputParam.cmpgnProgPnstaCd, // 캠페인진척현황코드
          pCnsltNo: this.lv_inputParam.cnsltNo
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            lv_Vm.positionUp = false
            lv_Vm.$bottomModal.close(lv_Vm.popup161)
            lv_Vm.fn_InitBottomSheet()

            // 마지막 조직 조회 데이터 셋팅
            lv_Vm.pOrgData160M = pData.rtnData.pOrgData
            if (lv_Vm.isMngr) {
              lv_Vm.lv_inputParam.hofOrgNo = lv_Vm.pOrgData160M.hofOrg.key
              lv_Vm.lv_inputParam.dofOrgNo = lv_Vm.pOrgData160M.dofOrg.key
              lv_Vm.lv_inputParam.fofOrgNo = lv_Vm.pOrgData160M.fofOrg.key
            }
            // 나머지 조회조건 세팅
            lv_Vm.lv_inputParam.custNm = pData.rtnData.pCustNm
            lv_Vm.lv_inputParam.cmpgnTypCd = pData.rtnData.pCmpgnTypCd
            lv_Vm.lv_inputParam.resuMngPrdIcluYn = pData.rtnData.pResuMngPrdIcluYn
            lv_Vm.lv_inputParam.cmpgnId = pData.rtnData.pCmpgnId
            lv_Vm.lv_inputParam.chnlCustScCd = pData.rtnData.pChnlCustScCd
            lv_Vm.lv_inputParam.cmpgnProgPnstaCd = pData.rtnData.pCmpgnProgPnstaCd
            // 캠페인 콤보 세팅
            lv_Vm.lv_CmpgnId = lv_Vm.lv_inputParam.cmpgnId
            // 검색 실행여부
            lv_Vm.isSearched = true
            // 검색 초기화 여부
            lv_Vm.isInit = pData.isInit
            if (lv_Vm.isInit) lv_Vm.isSearched = false
            // 조회조건 텍스트 세팅
            lv_Vm.lv_ReturnStr = pData.srchOpt

            lv_Vm.$nextTick(() => {
              // 캠페인고객 리스트 조회
              lv_Vm.fn_Submit()
            })
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.popup161)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM162P
     * 설명       : 캠페인고객 직접선정 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM162P () {
      this.popup162 = this.$bottomModal.open(MSPCM162P, {
        properties: {
          pCnsltNo: this.lv_cnsltNo
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popup162)
          },
          onPopupSearch: (param) => {
            this.$bottomModal.close(this.popup162)

            // 선택한 캠페인의 고객 리스트 조회
            // 캠페인명 콤보 변경
            this.lv_CmpgnId = param.cmpgnId
            // 서비스 파라미터 세팅
            this.lv_inputParam.cmpgnId = param.cmpgnId
            // 캠페인고객 리스트 재조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM163P
     * 설명       : 진행중인 캠페인 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM163P () {
      this.popup163 = this.$bottomModal.open(MSPCM163P, {
        properties: {
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popup163)
          },
          onPopupSearch: (param) => {
            this.$bottomModal.close(this.popup163)

            // 선택한 캠페인의 고객 리스트 조회
            // 캠페인명 콤보 변경
            this.lv_CmpgnId = param.cmpgnId
            // 서비스 파라미터 세팅
            this.lv_inputParam.cmpgnId = param.cmpgnId
            // 캠페인고객 리스트 재조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM164P
     * 설명       : 캠페인 고객정보 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM164P (item) {
      this.popup164 = this.$bottomModal.open(MSPCM164P, {
        properties: {
          pCMCmpgnCustInfoVO: item,         // 선택한 컬럼 item
          pOrgData160M: this.pOrgData160M   // 조직조회정보
        },
        listeners: {
          onPopupClose: (param) => {
            this.$bottomModal.close(this.popup164)
            // 새 고객 생성 시 리스트 재조회
            if (param) {
              this.isFirst = true
              window.vue.getStore('progress').dispatch('NONE')
              this.fn_SetProgressMsgPop(true)
              this.fn_Submit()
            }
          },
          onPopupSearch: (param) => {
            this.$bottomModal.close(this.popup164)

            // 선택한 캠페인의 고객 리스트 조회
            // 캠페인명 콤보 변경
            this.lv_CmpgnId = param.cmpgnId
            // 서비스 파라미터 세팅
            this.lv_inputParam.cmpgnId = param.cmpgnId
            // 캠페인고객 리스트 재조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      if(this.pOrgData160M.cnslt === undefined || this.pOrgData160M.cnslt === null || this.pOrgData160M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData160M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM160M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData160M = {}  // 조직 조회 데이터
                this.isSearched = false // 검색이력
                this.isInit = true
                this.isFirst = true
                this.fn_InitBottomSheet() // 바텀시트
        
                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
}
</script>
