/*
 * 업무구분: 고객관리
 * 화 면 명: MSPCM162P
 * 화면설명: 캠페인고객 직접선정
 * 작 성 일: 
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="캠페인고객 직접선정" :show-title="true" type="subpage" actionType="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P" @on-scroll-bottom="fn_LoadingData()" :topButton="true">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pt34 pb10">
        <ur-box-container alignH="start" componentid="" direction="row" class="row-box btn-question-wrap">
          <!-- 캠페인명 콤보 -->
          <span class="label-title min50 mb0">캠페인</span>
          <msp-bottom-select ref="bottomSelect" :items="cMCmpgnInfoVO" :disabled="cMCmpgnInfoVO.length === 0" v-model="lv_CmpgnId" @input="fn_ChangeCmpgnCombo" underline class="ns-dropdown-sheet select-resposive" placeholder="전체" bottom-title="캠페인"/>
          <mo-button color="normal" shape="solid" size="small" @click="fn_SelCmpgnDtl" :disabled="$bizUtil.isEmpty(lv_CmpgnId)" :class="$bizUtil.isEmpty(lv_CmpgnId)? 'btn-question disabled': 'btn-question'"></mo-button>
        </ur-box-container>
      </ur-box-container>

      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1 bd-T-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM116P">
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>

      <!--  검색영역 : 고객명 검색영역 //-->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="isEmptyView" size="small" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{inqrCnt}}</strong> 명</span>
              <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="cMCmpgnAddmObjCustInfoVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
              <mo-checkbox v-model="item.checked" :ref="`chk${index}`"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <!-- 고객명 -->
                  <span class="name txtSkip">{{item.custNm}}</span>
                  <!-- 고객유형 / 선정여부 -->
                  <mo-badge v-if="!$bizUtil.isEmpty(item.chnlCustScCdNm)" class="badge-sample-badge lightblue sm crTy-blue3" :text="item.chnlCustScCdNm" shape="status">{{item.chnlCustScCdNm}}</mo-badge> 
                  <mo-badge v-if="!$bizUtil.isEmpty(item.objChoiYn)" class="badge-sample-badge lightgreen sm crTy-blue3" :text="item.objChoiYn" shape="status">{{item.objChoiYn}}</mo-badge>
                </div>
                <!-- 생년월일 / 성별 -->
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.dob)}}</span><em>|</em><span class="crTy-bk1">{{item.sxdsCdNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span>
                    <span class="crTy-bk1">필수컨설팅
                      <span v-if="item.mndtCnstgCnsntRmdrDds === 0">N</span>
                      <span v-else-if="item.mndtCnstgCnsntRmdrDds <= 30"><b class="crTy-orange2">Y</b>{{"(D-" + item.mndtCnstgCnsntRmdrDds + ")"}}</span>
                      <span v-else-if="item.mndtCnstgCnsntRmdrDds >= 100">Y(D-99+)</span>
                      <span v-else>N</span>
                    </span>
                  </span>
                  <em>|</em>
                  <span class="crTy-bk1">{{item.celno}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
      <!-- NoData 영역  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

     <!-- 진입 시 출력하는 바텀시트 -->
      <mo-bottom-sheet ref="nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">{{bottomSheetMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      
      <!-- 상세정보 -->
      <mo-bottom-sheet ref="bottomSheetDtl" :close-btn="false" class="ns-bottom-sheet sheet-type-1 closebtn">
        <template v-slot:title>
          <!-- 캠페인명 -->
          <span>{{lv_CmpgnNm + ' (' + lv_CmpgnId + ')'}}</span>
          <div class="ns-btn-close" @click="fn_InitBottomSheet" name="닫기"></div>
        </template>
        <!-- 상세, 추가정보 -->
        <div class="content-area pl0 pr0">
          <div class="pl24 pr24">
            <ul class="bullet-type-1">
              <span v-html="lv_selectedData.cmpgnCntnt"/>
            </ul>
          </div>
          <div class="msp-detail-lst type-1 pb10">
            <div class="pb10 pt10 fwm">추가정보</div>
            <ul class="lst pt16 bd-T-Ty1">
              <li>
                <span class="tit">골프장</span>
                <span class="txt">{{lv_selectedData.prgPlcCdNm}}</span>
              </li>
              <li>
                <span class="tit">행사장소명/제휴처명</span>
                <span class="txt">{{lv_selectedData.cmpgnPlcNm}}</span>
              </li>
              <li>
                <span class="tit">강사유형</span>
                <span class="txt">{{lv_selectedData.lectrTypCdNm}}</span>
              </li>
              <li>
                <span class="tit">컨설턴트참석여부</span>
                <span class="txt">{{lv_selectedData.cnsltAtdncCdNm}}</span>
              </li>
              <li>
                <span class="tit">인원수(세미나T/O)</span>
                <span class="txt">{{lv_selectedData.cmpgnPsbNprsnFgr}}</span>
              </li>
              <li>
                <span class="tit">인원수(세미나신청)</span>
                <span class="txt">{{lv_selectedData.cmpgnAplNprsnFgr}}</span>
              </li>
            </ul>
          </div>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round white mr5" @click="fn_MoveMSPCM160M">캠페인 고객보기</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue ml5" @click="fn_InitBottomSheet">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="checkCount === 0" @click="fn_SubmitI">추가</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
      <div v-if="cMCmpgnAddmObjCustInfoVO.length > 0" class="ns-height70"></div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 고객검색
import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM162P',
  // 현재 화면 ID
  screenId: 'MSPCM162P',
  // 컴포넌트 선언
  components: {
    MSPCM116P,
    CustInfoBottomSheet
  },
  props: {
    pCnsltNo: String
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      
      // 제어 변수
      isSearched: false,        // 검색 실행 여부
      isInit: false,            // 검색 초기화 여부
      isMngr: false,            // 지점장 여부
      isOneOrMoreCheck: false,  // 전체선택
      disabled: false, 
      isLoading: false,
      isEmptyView: false,       // 데이터 없음 화면

      // 데이터 변수
      pOrgData162P: {},         // 최근 조직 조회 데이터
      toastMsg: '',             // toast 문구
      bottomSheetMsg: '',       // bottomSheet 문구
      popup116: {},             // MSPCM116P 고객검색
      lv_ReturnStr: [],         // 상세조회조건 텍스트 배열
      // 캠페인고객 리스트 조회
      cMCmpgnCustInfoVO: [],    // 인피니트 바인딩 리스트VO
      lv_inputParam: {          // 서비스호출 Obj
        cnsltNo: '',            // 컨설턴트번호
        cmpgnId: '',            // 캠페인ID
        custNm: '',             // 고객명
      },
      inqrCnt: 0,               // 총 조회건수
      msgCntnt: '',             // 서비스 호출결과 메시지
      // 더보기 key
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}],
      stndKeyVal: 'start',
      pageRowCnt: '20',         // 페이지 카운트
      // 캠페인 콤보 세팅
      lv_CmpgnNm: '',           // 캠페인명
      lv_CmpgnId: '',           // 캠페인ID
      cMCmpgnInfoVO : [],       // 캠페인정보VO
      // 캠페인선정대상고객목록VO
      cMCmpgnAddmObjCustInfoVO: [],
      msgScCd: '',              // 페이징여부 R
      char7cnt: '',
      // 캠페인 상세
      lv_selectedData: {},      // 선택한 캠페인 item
      // 캠페인고객직접선정
      lv_inputParamI: {
        cnsltNo: '',
        cmpgnId: '',
        cMCmpgnAddmObjCustInfoVO: []
      },

      lv_CustInfoBottomSheet: null // 고객카드바텀시트
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.cMCmpgnAddmObjCustInfoVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cMCmpgnAddmObjCustInfoVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cMCmpgnAddmObjCustInfoVO.length && this.cMCmpgnAddmObjCustInfoVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_inputParam.cnsltNo = _.cloneDeep(this.$props.pCnsltNo) // FC 사번

    // 캠페인 목록 조회
    this.fn_selListCmpgn()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.fn_InitBottomSheet()
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
		  this.$refs[`chk${index}`].onClick()
	  },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색
        this.fn_Clear()
      } else {
        // 조회를 하지 않았으면 팝업 종료
        this.$emit('onPopupClose')
      }

    },
  
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 초기화 버튼
     ******************************************************************************/
    fn_Clear () {
      // 초기화
      this.pOrgData162P = {}  // 조회 조직 데이터
      this.isSearched = false // 검색이력
      this.isInit = true
      this.lv_CmpgnId = ''
      this.lv_inputParam.cmpgnId = ''
      this.lv_inputParam.custNm = ''
      this.cMCmpgnAddmObjCustInfoVO = []
      this.fn_InitBottomSheet() // 바텀시트

      // 재조회
      this.fn_selListCmpgn()
    },

    /******************************************************************************
    * Function명 : fn_selListCmpgn
    * 설명       : 캠페인 목록 조회 (IF-CM-3027 F1CRM0162)
    *
   ******************************************************************************/
    fn_selListCmpgn () {
      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('FULL')

      const trnstId = 'txTSSCM53S6' // F1CRM0162
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      let pParams = {}

      // 초기화
      lv_Vm.stndKeyVal = 'start' // 더보기 최초조회
      lv_Vm.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      lv_Vm.msgScCd = ''
      lv_Vm.char7cnt = ''
      lv_Vm.inqrCnt = 0             // 총 조회건수
      lv_Vm.cMCmpgnCustInfoVO = []  // 인피니트 바인딩 데이터
      lv_Vm.cMCmpgnInfoVO = []
      lv_Vm.isEmptyView = false // 데이터가 없습니다 visible 여부

      pParams.cnsltNo = this.lv_inputParam.cnsltNo // 컨설턴트번호 (ZA_CHNL_ID : FC 사번)

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body

            let tmpCMCmpgnInfoVO = responseList.cMCmpgnInfoVO // 캠페인정보VO

            if (tmpCMCmpgnInfoVO !== null) {
              for (let j = 0; j < tmpCMCmpgnInfoVO.length; j++) {
                let tmpObj = {}
                tmpObj.value = tmpCMCmpgnInfoVO[j].cmpgnId
                tmpObj.text = tmpCMCmpgnInfoVO[j].cmpgnNm
                lv_Vm.cMCmpgnInfoVO.push(tmpObj)
              }
            }

            if (lv_Vm.cMCmpgnInfoVO !== null && lv_Vm.cMCmpgnInfoVO.length !== 0) {
              lv_Vm.bottomSheetMsg = "캠페인을 먼저 선택하셔야 직접 선정 대상 고객 확인이 가능합니다."
            } else {
              lv_Vm.bottomSheetMsg = "직접선정 가능한 캠페인이 없습니다."
              lv_Vm.isEmptyView = true
            }
            lv_Vm.$refs.nsbottomsheet.open()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      let lv_Vm = this

      lv_Vm.stndKeyVal = 'start' // 더보기 최초조회
      lv_Vm.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      lv_Vm.msgScCd = ''
      lv_Vm.char7cnt = ''
      lv_Vm.inqrCnt = 0             // 총 조회건수
      lv_Vm.cMCmpgnCustInfoVO = []  // 인피니트 바인딩 데이터
      lv_Vm.cMCmpgnAddmObjCustInfoVO = []
      lv_Vm.isEmptyView = false     // 데이터가 없습니다 visible 여부

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      lv_Vm.fn_selListCmpgnAddmObjCust() // 캠페인 대상 고객목록 조회
    },

    /******************************************************************************
    * Function명 : fn_selListCmpgnAddmObjCust
    * 설명       : 캠페인 대상 고객목록 조회 IF-CM-3028 (F1CRM0161)
    *
   ******************************************************************************/
    fn_selListCmpgnAddmObjCust () {
      if (this.stndKeyVal === 'end') return
      // const trnstId = 'txTSSCM53S4' // F1CRM0161
      const trnstId = 'txTSSCM48S5' // F1CRM0161
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')

      if (lv_Vm.lv_CmpgnId === '') {
        lv_Vm.lv_inputParam.cmpgnId = ' '
      }
      lv_Vm.lv_inputParam.msgScCd = lv_Vm.msgScCd
      lv_Vm.lv_inputParam.ZA_ICPS_WHL_SDRV_CNT = lv_Vm.char7cnt
      let pParams = lv_Vm.lv_inputParam

      lv_Vm.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body.cMCmpgnAddmObjCustInfoVO
            lv_Vm.char7cnt = response.body.za_ICPS_WHL_SDRV_CNT

            if (responseList === null) {
              lv_Vm.isEmptyView = true
              lv_Vm.cMCmpgnAddmObjCustInfoVO = []
            } else {
              responseList.forEach((item) => {
                item.checked = false
                lv_Vm.cMCmpgnAddmObjCustInfoVO.push(item)
              })

              if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_inputParam.custNm)) { // 조회조건 고객명이 있으면 필터링
                let regex = new RegExp(`^${lv_Vm.lv_inputParam.custNm}`)
                lv_Vm.cMCmpgnAddmObjCustInfoVO = lv_Vm.cMCmpgnAddmObjCustInfoVO.filter(item => regex.test(item.custNm))
              }
              lv_Vm.inqrCnt = lv_Vm.cMCmpgnAddmObjCustInfoVO.length
            }
          } else {
            lv_Vm.isEmptyView = true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          // 더보기 키값
          lv_Vm.stndKeyList = response.trnstComm.stndKeyList
          if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
            // 다음페이지 데이터 존재안함(마지막 페이지)
            lv_Vm.stndKeyVal = 'end'
            lv_Vm.msgScCd = ''
          } else {
            // 다음페이지 데이터 존재함
            lv_Vm.stndKeyVal = 'more'
            lv_Vm.msgScCd = 'R'
          }
          
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명  : fn_SelCmpgnDtl
     * 설명        : 선택한 캠페인 상세내용 조회 및 바텀시트 호출
     ******************************************************************************/
    fn_SelCmpgnDtl () {
      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('FULL')

      const trnstId = 'txTSSCM53S5' // F1CRM0160
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      let pParams = {}

      pParams.cmpgnClsfKey = this.lv_CmpgnId // 캠페인 GUID (OBJKEY_A) / 캠페인ID (EXTERNAL_ID)

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            if (response.body.msgScCd === 'S' || response.body.msgScCd === null) {
              let responseList = response.body.cMCmpgnDtlVO

              for (let i = 0; i < responseList.length; i++) {
                lv_Vm.lv_selectedData = {}
                lv_Vm.lv_selectedData.cmpgnCntnt = responseList[i].cmpgnCntnt
                lv_Vm.lv_selectedData.prgPlcCdNm = responseList[i].prgPlcCdNm // 진행장소코드명 (ZZGOLF_PLACEC)
                lv_Vm.lv_selectedData.cmpgnPlcNm = responseList[i].cmpgnPlcNm // 캠페인장소명 (ZZPLACE_NAME)
                lv_Vm.lv_selectedData.lectrTypCdNm = responseList[i].lectrTypCdNm // 강사유형코드명 (ZZLECTR_TYPEC)
                lv_Vm.lv_selectedData.cnsltAtdncCdNm = responseList[i].cnsltAtdncCdNm // 컨설턴트참석코드명 (ZZFC_ATTENDC)
                lv_Vm.lv_selectedData.cmpgnPsbNprsnFgr = responseList[i].cmpgnPsbNprsnFgr // 캠페인가능인원수 (ZZEXPT_NUMBER)
                lv_Vm.lv_selectedData.cmpgnAplNprsnFgr = responseList[i].cmpgnAplNprsnFgr // 캠페인신청인원수 (ZA_TOT_CNT)

                lv_Vm.$nextTick(() => {
                  // 바텀시트 오픈
                  lv_Vm.$refs.bottomSheetDtl.open()
                })
              }
            } else {
              confirm.dispatch('ADD', response.body.msgCntnt)
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_SubmitI
      * 설명       : 확인 버튼 클릭 핸들러
    ******************************************************************************/
    fn_SubmitI () {
      if (this.checkCount === 0) return
      this.fn_DataSetI()
      this.fn_insCmpgnObjCust()
    },

    /******************************************************************************
      * Function명 : fn_DataSetI
      * 설명       : 캠페인 고객선정 저장 처리 전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetI () {
      let lv_Vm = this

      lv_Vm.lv_inputParamI = {
        cnsltNo: '',
        cmpgnId: '',
        cMCmpgnAddmObjCustInfoVO: []
      }

      lv_Vm.lv_inputParamI.cnsltNo = lv_Vm.lv_inputParam.cnsltNo
      lv_Vm.lv_inputParamI.cmpgnId = lv_Vm.lv_CmpgnId

      for (let i = 0; i < lv_Vm.checkCount ; i++) {
        let saveObj = {}

        saveObj.cmpgnId = '' // 캠페인ID (EXTERNAL_ID)
        saveObj.chnlCustId = lv_Vm.selectItems[i].chnlCustId // 채널고객ID (ZA_AGT_FILE_ID)
        saveObj.cnsltNo = '' // 컨설턴트번호 (ZA_CHNL_ID)
        saveObj.custId = lv_Vm.selectItems[i].custId // 고객ID (PARTNER)
        saveObj.custNm = lv_Vm.selectItems[i].custNm // 고객명 (NAME_LAST)
        saveObj.sxdsCd = lv_Vm.selectItems[i].sxdsCd // 성별코드 (SEX)
        saveObj.dob = lv_Vm.selectItems[i].dob // 생년월일 (BIRTHDT)
        saveObj.celno = lv_Vm.selectItems[i].celno // 휴대폰번호 (ZA_CELPH_TELNO)
        saveObj.objCustCretYn = '' // 대상고객생성여부 (ZA_OBJ_CUST_CRET_YN)
        saveObj.objCustCretCd = '' // 대상고객생성코드  (ZA_OBJ_CUST_CRET_CD)
        saveObj.idTypCd = lv_Vm.selectItems[i].idTypCd // ID유형코드 (ID_TYPE)
        saveObj.guid = lv_Vm.selectItems[i].guid // GUID (IDNUMBER)

        this.lv_inputParamI.cMCmpgnAddmObjCustInfoVO.push(saveObj)
      }
    },
    /******************************************************************************
    * Function명 : fn_insCmpgnObjCust
    * 설명       : 캠페인 고객선정 저장 IF-CM-3029 (F1CRM0163)
    *
   ******************************************************************************/
    fn_insCmpgnObjCust () {
      const trnstId = 'txTSSCM53I1' // F1CRM0163
      const auth = 'I'
      var lv_Vm = this
      let confirm = this.getStore('confirm')

      this.isLoading = true

      let pParams = this.lv_inputParamI

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body
            lv_Vm.msgCntnt = responseList.msgCntnt

            // if (responseList.msgScCd === 'S') { // 성공메시지 
            //   confirm.dispatch('ADD', lv_Vm.msgCntnt)
            // } else { // 고객별 실패메시지
            //   confirm.dispatch('ADD', lv_Vm.msgCntnt)
            // }
            if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.msgCntnt)) {
              lv_Vm.getStore('toast').dispatch('ADD', lv_Vm.msgCntnt)
            } else {
              lv_Vm.getStore('toast').dispatch('ADD', '정상 처리 되었습니다.')
            }

            lv_Vm.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}]
            lv_Vm.stndKeyVal = '' // 더보기 최초조회
            lv_Vm.msgScCd = ''
            lv_Vm.cMCmpgnAddmObjCustInfoVO = []
            for (let i in lv_Vm.cMCmpgnAddmObjCustInfoVO) lv_Vm.cMCmpgnAddmObjCustInfoVO[i].checked = false
            lv_Vm.char7cnt = ''
            lv_Vm.isLoading1 = false

            // 목록 재조회
            lv_Vm.fn_selListCmpgnAddmObjCust()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
          for (let i in lv_Vm.cMCmpgnAddmObjCustInfoVO) lv_Vm.cMCmpgnAddmObjCustInfoVO[i].checked = false // 체크박스 선택 해제
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // }
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 리스트 스크롤시 로딩 함수
    ******************************************************************************/
    fn_LoadingData () {
      let lv_Vm = this
      if (lv_Vm.stndKeyVal === 'end') return

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('PART')

      // 페이징 처리(서비스 재실행)
      lv_Vm.fn_selListCmpgnAddmObjCust()
      if (lv_Vm.isOneOrMoreCheck) lv_Vm.isOneOrMoreCheck = false  // 전체체크 해제
    },

    /******************************************************************************
     * Function명 : fn_ChangeCmpgnCombo
     * 설명       : 캠페인명 콤보 입력 이벤트
     ******************************************************************************/
    fn_ChangeCmpgnCombo () {
      let lv_Vm = this

      // 캠페인명 세팅
      lv_Vm.lv_CmpgnNm = lv_Vm.cMCmpgnInfoVO.find(e => e.value === lv_Vm.lv_CmpgnId).text
      lv_Vm.lv_inputParam.cmpgnId = lv_Vm.lv_CmpgnId
      lv_Vm.isSearched = true
      // 캠페인고객 리스트 재조회
      lv_Vm.fn_Submit()
    },
    
    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        // 진입 시 알림
        if (lv_Vm.$refs.nsbottomsheet !== undefined) {
          lv_Vm.$refs.nsbottomsheet.close()
        }
        // 캠페인 상세내용
        if (lv_Vm.$refs.bottomSheetDtl !== undefined) {
          lv_Vm.$refs.bottomSheetDtl.close()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.cMCmpgnAddmObjCustInfoVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.cMCmpgnAddmObjCustInfoVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.cMCmpgnAddmObjCustInfoVO[n].checked = true
            } else {
              lv_Vm.cMCmpgnAddmObjCustInfoVO[n].checked = false
            }
          }
        }
      })
    },

    /*********************************************************
     * Function명 : fn_OpenMSPCM116P
     * 설명       : 고객검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: this.$options.screenId,
          pOrgData: this.pOrgData162P, // 최근 조직 조회 데이터
          pSearchKeyword: this.lv_inputParam.custNm // 고객명
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 파라미터 세팅
            this.lv_inputParam.custNm = pData.rtnData.searchKeyword
            // 검색 초기화 여부
            this.isInit = pData.isInit
            // 검색 실행 여부
            this.isSearched = true
            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt
            // 재조회
            if (!this.$bizUtil.isEmpty(this.lv_CmpgnId)) {
              this.fn_Submit()
            }

            this.$bottomModal.close(this.popup116)
          }
        }
      })
    },

    /*********************************************************
     * Function명 : fn_MoveMSPCM160M
     * 설명       : 캠페인고객 메인화면으로 이동
     *             선택한 캠페인 고객 조회
     *********************************************************/
    fn_MoveMSPCM160M () {
      this.$emit('onPopupSearch', {cmpgnId: this.lv_CmpgnId})
    },
  }
}
</script>
