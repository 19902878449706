/*
 * 업무구분: 고객
 * 화 면 명: MSPCM163P
 * 화면설명: 진행중인 캠페인
 * 접근권한: 
 * 작 성 일: 2023.02.02
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="진행중인 캠페인" :show-title="true" type="subpage" :topButton="true">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{totCnt}}</strong> 개</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="lv_campaignData">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents" @click="fn_SelCmpgnDtl(item)">
                <!-- 캠페인명 -->
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.name}}</span>
                </div>
                <!-- 시작일~종료일 / 기한 -->
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{item.date1}}</span><span class="ml4" :class="{'crTy-orange2' : item.isEnd === true}">{{item.date2}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>

      <!-- NoData 영역  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <mo-bottom-sheet ref="bottomSheetDtl" :close-btn="false" class="ns-bottom-sheet sheet-type-1 closebtn">
        <template v-slot:title>
          <!-- 캠페인명 / 기간 -->
          <span>{{lv_selectedData.name}}</span>
          <div class="sheet-date-wrap">
            <span>{{lv_selectedData.date1}}</span> <span>{{lv_selectedData.date2}}</span>
          </div>
          <div class="ns-btn-close" @click="fn_CtrlBottomSheet('close')" name="닫기"></div>
        </template>
        <!-- 상세, 추가정보 -->
        <div class="content-area pl0 pr0">
          <div class="pl24 pr24">
            <ul class="bullet-type-1">
              <span v-html="lv_selectedData.cmpgnCntnt"/>
            </ul>
            <!-- <span v-html="lv_selectedData.cmpgnCntnt"></span> -->
          </div>
          <div class="msp-detail-lst type-1 pb10">
            <div class="pb10 pt10 fwm">추가정보</div>
            <ul class="lst pt16 bd-T-Ty1">
              <li>
                <span class="tit">골프장</span>
                <span class="txt">{{lv_selectedData.prgPlcCdNm}}</span>
              </li>
              <li>
                <span class="tit">행사장소명/제휴처명</span>
                <span class="txt">{{lv_selectedData.cmpgnPlcNm}}</span>
              </li>
              <li>
                <span class="tit">강사유형</span>
                <span class="txt">{{lv_selectedData.lectrTypCdNm}}</span>
              </li>
              <li>
                <span class="tit">컨설턴트참석여부</span>
                <span class="txt">{{lv_selectedData.cnsltAtdncCdNm}}</span>
              </li>
              <li>
                <span class="tit">인원수(세미나T/O)</span>
                <span class="txt">{{lv_selectedData.cmpgnPsbNprsnFgr}}</span>
              </li>
              <li>
                <span class="tit">인원수(세미나신청)</span>
                <span class="txt">{{lv_selectedData.cmpgnAplNprsnFgr}}</span>
              </li>
            </ul>
          </div>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round white mr5" @click="fn_CtrlBottomSheet('cmpgn')">캠페인 고객보기</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue ml5" @click="fn_CtrlBottomSheet('close')">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM163P",
    screenId: "MSPCM163P",
    props: {

    },
    components: {
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isEmptyView: false,

        userInfo: {},
        totCnt: 0,
        lv_campaignData: [], // 진행중인 캠페인 리스트 VO
        lv_selectedData: {}, // 선택한 캠페인 item
        lv_CmpgnId: ''  // 선택한 캠페인ID
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo

      // 데이터 초기화
      this.lv_campaignData = []
      
      // 리스트 조회
      this.fn_SelListCmpgn()
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명  : fn_HistoryBack
       * 설명        : 뒤로가기 (history back)
       ******************************************************************************/
      fn_HistoryBack () {
        this.$emit('onPopupClose');
      },

      /******************************************************************************
       * Function명  : fn_SelListCmpgn
       * 설명        : 진행중인 캠페인 리스트 조회
       ******************************************************************************/
      fn_SelListCmpgn () {
        let lv_Vm = this
        let pParams = {}
        let trnstId = 'txTSSAP05S1'

        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('SKEL_LIST')
        
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null) {
              if (response.body.aPCmpgnVO !== null && response.body.aPCmpgnVO.length > 0) {
                let lv_CmpgnList = response.body.aPCmpgnVO
                lv_CmpgnList.forEach(
                  (item) => {
                    let lv_Item = {}
                    lv_Item.name = item.cmpgnNm
                    lv_Item.date1 = item.cmpgnPrd
                    if (item.dDay >= 0) {
                      lv_Item.date2 = '(D-' + item.dDay + ')'
                    } else {
                      lv_Item.date2 = ''
                    }

                    lv_Item.isNew = item.newBoolean // new 표시
                    lv_Item.isEnd = Number(item.dDay) <= 30 // D- 30일전 색깔 표시
                    lv_Item.cmpgnStrYmd = item.cmpgnStrYmd
                    lv_Item.cmpgnGuid = item.cmpgnGuid
                    lv_Item.cmpgnId = item.cmpgnId
                    lv_Vm.lv_campaignData.push(lv_Item)
                  }
                )
                lv_Vm.totCnt = lv_Vm.lv_campaignData.length
              } else {
                lv_Vm.isEmptyView = true
              }
            } else {
              lv_Vm.isEmptyView = true
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
        .catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
       * Function명  : fn_SelCmpgnDtl
       * 설명        : 선택한 캠페인 상세내용 조회 및 바텀시트 호출
       ******************************************************************************/
      fn_SelCmpgnDtl (item) {
        let lv_Vm = this
        const trnstId = 'txTSSCM53S5' 
        const auth = 'S'
        let pParams = {}

        pParams.cmpgnClsfKey = item.cmpgnGuid// 캠페인 GUID (OBJKEY_A) / 캠페인ID (EXTERNAL_ID)
        this.lv_CmpgnId = item.cmpgnId

        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('FULL')
        
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null && response.body !== '') {
              if ((response.body.msgScCd === 'S' || response.body.msgScCd === null) && response.body.cMCmpgnDtlVO !== null && response.body.cMCmpgnDtlVO.length > 0 ) {
                let responseList = response.body.cMCmpgnDtlVO
                lv_Vm.lv_selectedData = {}
                lv_Vm.lv_selectedData.cmpgnCntnt = responseList[0].cmpgnCntnt.replace(/(?:\r\n|\r|\n)/g, '<br />')
                lv_Vm.lv_selectedData.date1 = item.date1
                lv_Vm.lv_selectedData.date2 = item.date2
                lv_Vm.lv_selectedData.cmpgnId = item.cmpgnId
                lv_Vm.lv_selectedData.name = item.name
                lv_Vm.lv_selectedData.prgPlcCdNm = responseList[0].prgPlcCdNm // 진행장소코드명 (ZZGOLF_PLACEC)
                lv_Vm.lv_selectedData.cmpgnPlcNm = responseList[0].cmpgnPlcNm // 캠페인장소명 (ZZPLACE_NAME)
                lv_Vm.lv_selectedData.lectrTypCdNm = responseList[0].lectrTypCdNm // 강사유형코드명 (ZZLECTR_TYPEC)
                lv_Vm.lv_selectedData.cnsltAtdncCdNm = responseList[0].cnsltAtdncCdNm // 컨설턴트참석코드명 (ZZFC_ATTENDC)
                lv_Vm.lv_selectedData.cmpgnPsbNprsnFgr = responseList[0].cmpgnPsbNprsnFgr // 캠페인가능인원수 (ZZEXPT_NUMBER)
                lv_Vm.lv_selectedData.cmpgnAplNprsnFgr = responseList[0].cmpgnAplNprsnFgr // 캠페인신청인원수 (ZA_TOT_CNT)

                lv_Vm.$nextTick(() => {
                  // 바텀시트 오픈
                  lv_Vm.fn_CtrlBottomSheet('open')
                })
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /******************************************************************************
       * Function명  : fn_CtrlBottomSheet
       * 설명        : 바텀시트 컨트롤
       ******************************************************************************/
      fn_CtrlBottomSheet (str) {
        let lv_Vm = this

        if (lv_Vm.$refs !== undefined) {
          if (lv_Vm.$refs.bottomSheetDtl !== undefined) {
            if (str === 'open') lv_Vm.$refs.bottomSheetDtl.open()
            else if (str === 'cmpgn') {
              // 선택한 캠페인 고객 리스트 호출
              this.$emit('onPopupSearch', {cmpgnId: this.lv_CmpgnId})
            }
            else lv_Vm.$refs.bottomSheetDtl.close()
          }
        }
      }
    }
  };
</script>
<style scoped>
</style>