/*
 * 업무구분: 계약관리
 * 화 면 명: MSPCM161P
 * 화면설명: 캠페인고객 상세조회조건
 * 접근권한: 
 * 작 성 일: 2023.01.25
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="고객검색" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">

        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container v-if="lv_IsMngr" direction="column" alignV="start" class="ns-sch-area">
          <SearchCondOrgCM ref="refSearchOrg" v-model="lv_OrgSearchData" :isFofType="1" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultPM"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb10">
            <span class="info-title fs14rem fwn crTy-bk7">캠페인명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="lv_CMCmpgnInfoVO" v-model="lv_CmpgnId" underline class="ns-dropdown-sheet mb0" bottom-title="캠페인명" placeholder="전체" closeBtn/>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field ref="error" v-model="lv_CustNm" type="none" class="mb0" clearable underline placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">고객구분</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_ChnlCustScCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_CMChnlCustScCdVO" :value="item.value" :key="idx" v-show="idx < 3">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_ChnlCustScCd" class="chip-type-wrap mt10">
              <mo-segment-button v-for="(item, idx) in lv_CMChnlCustScCdVO" :value="item.value" :key="idx" v-show="idx > 2">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">캠페인유형</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_CmpgnTypCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnTypVO" :value="item.value" :key="idx" v-show="idx < 3">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_CmpgnTypCd" class="chip-type-wrap mt10">
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnTypVO" :value="item.value" :key="idx" v-show="idx > 2 && idx < 6">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_CmpgnTypCd" class="chip-type-wrap mt10">
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnTypVO" :value="item.value" :key="idx" v-show="idx > 5 && idx < 9">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_CmpgnTypCd" class="chip-type-wrap mt10"> <!--  2+3 병합 버튼 포함 class: right-merge -->
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnTypVO" :value="item.value" :key="idx" v-show="idx > 8">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">성과관리기간포함</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_ResuMngPrdIcluYn" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnOptValVO" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mb80">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">진척현황</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_CmpgnProgPnstaCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnProgPnstaCdVO" :value="item.value" :key="idx" v-show="idx < 2">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_CmpgnProgPnstaCd" class="chip-type-wrap mt10">
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnProgPnstaCdVO" :value="item.value" :key="idx" v-show="idx > 1 && idx < 4">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_CmpgnProgPnstaCd" class="chip-type-wrap one-ea mt10">
              <mo-segment-button v-for="(item, idx) in lv_CMCmpgnProgPnstaCdVO" :value="item.value" :key="idx" v-show="idx > 3">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div class="ns-height40"></div>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isDisabled" @click="fn_Search">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import cmConstants from '@/config/constants/cmConstants.js'

export default {
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  name: 'MSPCM161P',
  screenId: 'MSPCM161P',
  components: {
    SearchCondOrgCM,
    MspBottomSelect
  },
  props: {
    pIsMngr: Boolean, // 조직combox 권한자여부
    pOrgData: {
      type: Object,
      default: {}
    },
    pCustNm: String, // 고객명
    pCmpgnTypCd: String, // 캠페인유형코드
    pResuMngPrdIcluYn: String, // 성과관리기간포함여부
    pCmpgnId: String, // 캠페인ID
    pChnlCustScCd: String, // 채널고객구분코드
    pCmpgnProgPnstaCd: String, // 캠페인진척현황코드
    pCnsltNo: String  // FC사번
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')
    
    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_IsMngr = this.$props.pIsMngr // 관리자여부
    this.lv_OrgSearchData = _.cloneDeep(this.$props.pOrgData)     // 조직정보
    this.lv_CustNm = _.cloneDeep(this.$props.pCustNm) // 고객명
    this.lv_CmpgnTypCd = _.cloneDeep(this.$props.pCmpgnTypCd) // 캠페인유형코드
    this.lv_ResuMngPrdIcluYn = _.cloneDeep(this.$props.pResuMngPrdIcluYn) // 성과관리기간포함여부
    this.lv_CmpgnId = _.cloneDeep(this.$props.pCmpgnId) // 캠페인ID
    this.lv_ChnlCustScCd = _.cloneDeep(this.$props.pChnlCustScCd) // 채널고객구분코드
    this.lv_CmpgnProgPnstaCd = _.cloneDeep(this.$props.pCmpgnProgPnstaCd) // 캠페인진척현황코드
    this.lv_CnsltNo = _.cloneDeep(this.$props.pCnsltNo) // FC 사번

    if (this.lv_CnsltNo[0] !== '1') {
      // 임직원 아닐때만 최초진입 시 조회로직 실행: fc사번 첫자리로 구분(임직원이나, 파라미터로 사용되는 fc사번이 본인의 것이 아닐 수 있음)
      // 캠페인유형 콤보 조회
      this.fn_selCmpgnInitVal()
    } else {
      this.lv_CmpgnTypCd = '99'
      this.lv_ChnlCustScCd = '99'
      this.lv_CmpgnProgPnstaCd = '99'
    }
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  computed: {
    isDisabled () {
      let rslt = false
      if (this.lv_IsMngr && !_.isEmpty(this.lv_RtnOrgSearchData)) {
        if (this.lv_RtnOrgSearchData.cnslt.key === '0') rslt = true
      }
      return rslt
    }
  },
  watch: {
    lv_CustNm () {
      let englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.lv_CustNm)) {
        if (englishOrKorean.test(this.lv_CustNm)) {
          this.lv_CustNm = this.lv_CustNm.toUpperCase()
        }
      }
    }
  },

  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      userInfo: {},
      lv_ReturnStr: [], // 조회조건 텍스트 배열

      // 상세조회 데이터
      lv_CustNm: '', // 고객명
      lv_CmpgnTypCd: '', // 캠페인유형코드
      lv_CMCmpgnTypVO : [{value: '99', text: '전체'}], // 캠페인유형VO
      lv_ResuMngPrdIcluYn: '', // 성과관리기간포함여부
      lv_CMCmpgnOptValVO : cmConstants.CMPGN_OPT_VAL_CD, // 성과관리기간포함여부 VO
      lv_CmpgnId: '', // 캠페인ID
      lv_CMCmpgnInfoVO : [], // 캠페인정보VO
      lv_ChnlCustScCd: '', // 채널고객구분코드
      lv_CMChnlCustScCdVO : [{value: '99', text: '전체'}], // 고객유형VO
      lv_CmpgnProgPnstaCd: '', // 캠페인진척현황코드
      lv_CMCmpgnProgPnstaCdVO : [{value: '99', text: '전체'}], // 캠페인진척현황코드VO
      lv_CnsltNo: '', // 컨설턴트사번

      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보
      lv_IsMngr: false, // 관리자여부
      lv_AlertPop: '' // bottom alert component
    }
  },
  
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultPM()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultPM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
        this.allChk = true

        // 다른 컨설턴트 선택 시
        if (this.lv_CnsltNo !== this.lv_RtnOrgSearchData.cnslt.key) {
          // '전체' 선택 시
          if (this.lv_RtnOrgSearchData.cnslt.key === '0') {
            this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해 주세요.')
            // this.fn_ResetBtnClick()
            return
          }
          
          if (this.lv_RtnOrgSearchData.cnslt.key[0] !== '1') {
            // 임직원이 아닐 때, 캠페인 유형 조회
            this.fn_selCmpgnInitVal()
          } else {
            // 임직원일 때, segment 초기화
            this.lv_CmpgnId = ''
            this.lv_CmpgnTypCd = '99' // 캠페인유형코드
            this.lv_CMCmpgnTypVO = [{value: '99', text: '전체'}] // 캠페인유형VO
            this.lv_ChnlCustScCd = '99' // 채널고객구분코드
            this.lv_CMChnlCustScCdVO = [{value: '99', text: '전체'}] // 고객유형VO
            this.lv_CmpgnProgPnstaCd = '99' // 캠페인진척현황코드
            this.lv_CMCmpgnProgPnstaCdVO = [{value: '99', text: '전체'}] // 캠페인진척현황코드VO
          }
        }
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
    * Function명 : fn_selCmpgnInitVal
    * 설명       : 캠페인유형 콤보 조회
    *
   ******************************************************************************/
    fn_selCmpgnInitVal () {
      const trnstId = 'txTSSCM53S1' // F1CRM0155, // F1CRM0156
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      let pParams = {}

      pParams.resuMngPrdIcluYn = 'X' // 성과관리기간포함여부 (CHAR1_ALV : 성과기간 포함여부 Default: ‘X’)
      pParams.cnsltNo = this.lv_CnsltNo
      // 임직원의 경우, 선택한 컨설턴트 사번으로 조회
      if (!_.isEmpty(this.lv_RtnOrgSearchData)) {
        if (this.lv_IsMngr && this.lv_CnsltNo !== this.lv_RtnOrgSearchData.cnslt.key) { 
          pParams.cnsltNo = this.lv_RtnOrgSearchData.cnslt.key
        }
      }

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            if (response.body.msgScCd === 'S' || response.body.msgScCd === null) {
              let responseList = response.body

              let tmpCMCmpgnTypVO = responseList.cMCmpgnTypVO // 캠페인유형VO

              lv_Vm.lv_CMCmpgnTypVO = []
              if (tmpCMCmpgnTypVO !== null) {
                tmpCMCmpgnTypVO.forEach(element => {
                  let lv_Item = {}
                  lv_Item.value = element.cmpgnTypCd
                  lv_Item.text = element.cmpgnTypCdNm
                  lv_Vm.lv_CMCmpgnTypVO.push(lv_Item)
                })
                if (lv_Vm.lv_CmpgnTypCd === '' || lv_Vm.lv_CmpgnTypCd === '99') lv_Vm.lv_CmpgnTypCd = lv_Vm.lv_CMCmpgnTypVO[0].value  // 캠페인유형 초기값 셋팅 - '전체'
              }

              let tmpCMChnlCustScCdVO = responseList.cMChnlCustScCdVO // 고객유형VO

              if (tmpCMChnlCustScCdVO !== null) {
                lv_Vm.lv_CMChnlCustScCdVO = []

               tmpCMChnlCustScCdVO.forEach(element => {
                  let lv_Item = {}
                  lv_Item.value = element.chnlCustScCd
                  lv_Item.text = element.chnlCustScCdNm
                  lv_Vm.lv_CMChnlCustScCdVO.push(lv_Item)
                })
                if (lv_Vm.lv_ChnlCustScCd === '' || lv_Vm.lv_ChnlCustScCd === '99') lv_Vm.lv_ChnlCustScCd = lv_Vm.lv_CMChnlCustScCdVO[0].value  // 고객유형 초기값 셋팅 - '전체'
              }

              let tmpCMCmpgnProgPnstaCdVO = responseList.cMCmpgnProgPnstaCdVO // 캠페인진척현황코드VO

              if (tmpCMCmpgnProgPnstaCdVO !== null) {
                lv_Vm.lv_CMCmpgnProgPnstaCdVO = []

                tmpCMCmpgnProgPnstaCdVO.forEach(element => {
                  let lv_Item = {}
                  lv_Item.value = element.cmpgnProgPnstaCd
                  lv_Item.text = element.cmpgnProgPnstaCdNm
                  lv_Vm.lv_CMCmpgnProgPnstaCdVO.push(lv_Item)
                })
                if (lv_Vm.lv_CmpgnProgPnstaCd === '' || lv_Vm.lv_CmpgnProgPnstaCd === '99') lv_Vm.lv_CmpgnProgPnstaCd = lv_Vm.lv_CMCmpgnProgPnstaCdVO[0].value  // 캠페인진척현황 초기값 셋팅 - '전체'
              }

              let tmpCMCmpgnInfoVO = responseList.cMCmpgnInfoVO // 캠페인정보VO
              let selectedObj = {}

              if (tmpCMCmpgnInfoVO !== null) {
                lv_Vm.lv_CMCmpgnInfoVO = []

                for (let j = 0; j < tmpCMCmpgnInfoVO.length; j++) {
                  let tmpObj = {}
                  tmpObj.value = tmpCMCmpgnInfoVO[j].cmpgnId
                  tmpObj.text = tmpCMCmpgnInfoVO[j].cmpgnNm
                  lv_Vm.lv_CMCmpgnInfoVO.push(tmpObj)

                  if (lv_Vm.lv_CmpgnId !== undefined) {
                    if (tmpObj.value === lv_Vm.lv_CmpgnId) {
                      selectedObj = tmpObj
                    }
                  }
                }
                if (selectedObj !== undefined && selectedObj.value !== undefined) {
                  lv_Vm.lv_CmpgnId = selectedObj.value
                } else {
                  if (lv_Vm.lv_CmpgnId === '') lv_Vm.lv_CmpgnId = lv_Vm.lv_CMCmpgnInfoVO[0].value // 캠페인명 초기값 셋팅 - '전체'
                }
              }
            } else {
              confirm.dispatch('ADD', response.body.msgCntnt)
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // }
    },

    /******************************************************************************
     * Function명 : fn_ResetBtnClick
     * 설명       : 상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_ResetBtnClick (e) {
      // 조직
      this.lv_OrgSearchData = {}
      this.lv_RtnOrgSearchData = {}
      // 상세조회조건
      this.lv_CustNm = '' // 고객명 검색어 초기화
      this.lv_ResuMngPrdIcluYn = 'X' // 성과관리기간포함여부 초기화

      this.lv_CmpgnId = ''
      this.lv_CmpgnTypCd = '99' // 캠페인유형코드
      this.lv_CMCmpgnTypVO = [{value: '99', text: '전체'}] // 캠페인유형VO
	    this.lv_ChnlCustScCd = '99' // 채널고객구분코드
      this.lv_CMChnlCustScCdVO = [{value: '99', text: '전체'}] // 고객유형VO
	    this.lv_CmpgnProgPnstaCd = '99' // 캠페인진척현황코드
      this.lv_CMCmpgnProgPnstaCdVO = [{value: '99', text: '전체'}] // 캠페인진척현황코드VO

      // 임직원이 아닐 경우, 최초조회 재실시
      if (!this.lv_IsMngr) {
        this.fn_selCmpgnInitVal()
      }
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_Search () {
      // 초기화 여부
      let isInit = false
      if (this.lv_IsMngr) {
        if (this.$bizUtil.isEmpty(this.lv_CustNm) && this.lv_CmpgnTypCd === '99' && this.lv_ChnlCustScCd === '99' && this.lv_ResuMngPrdIcluYn === 'X' && this.lv_CmpgnProgPnstaCd === '99') {
          if (this.userInfo.userId === this.lv_RtnOrgSearchData.cnslt.key) isInit = true
          else isInit = false
        } else isInit = false
      } else {
        if (this.$bizUtil.isEmpty(this.lv_CustNm) && this.$bizUtil.isEmpty(this.lv_CmpgnTypCd) && this.$bizUtil.isEmpty(this.lv_ChnlCustScCd) && this.lv_ResuMngPrdIcluYn === 'X' && this.$bizUtil.isEmpty(this.lv_CmpgnProgPnstaCd)) {
          isInit = true
        } else isInit = false
      }

      // return data set
      let t_RtnData = {
        // pOrgData: _.cloneDeep(this.lv_OrgSearchData), // 조직정보
        pOrgData: _.cloneDeep(this.lv_RtnOrgSearchData),
        pCustNm: this.lv_CustNm, // 고객명
        pCmpgnTypCd: this.lv_CmpgnTypCd, // 캠페인유형코드
        pResuMngPrdIcluYn: this.lv_ResuMngPrdIcluYn, // 성과관리기간포함여부
        pCmpgnId: this.lv_CmpgnId, // 캠페인ID
        pChnlCustScCd: this.lv_ChnlCustScCd, // 채널고객구분코드
        pCmpgnProgPnstaCd: this.lv_CmpgnProgPnstaCd, // 캠페인진척현황코드
      }

      // 조회조건 텍스트 배열
      this.lv_ReturnStr = [] // 초기화
      // 임직원일 때
      if (this.lv_IsMngr) {
        // 임직원 사번
        if (this.lv_CnsltNo[0] === '1' || this.lv_RtnOrgSearchData.cnslt.key[0] === '1') {
          this.lv_ReturnStr = [this.lv_CustNm, '전체', '전체', '전체', '전체']
        } else {
          // 조회조건: 고객명, 고객구분, 캠페인유형, 성과관리기간포함, 진척현황
          this.lv_ReturnStr = [this.lv_CustNm, this.lv_CMChnlCustScCdVO.find(e => e.value === this.lv_ChnlCustScCd).text, 
                              this.lv_CMCmpgnTypVO.find(e => e.value === this.lv_CmpgnTypCd).text,
                              this.lv_CMCmpgnOptValVO.find(e => e.value === this.lv_ResuMngPrdIcluYn).text, 
                              this.lv_CMCmpgnProgPnstaCdVO.find(e => e.value === this.lv_CmpgnProgPnstaCd).text]
        }
      } else {
        // 조회조건: 고객명, 고객구분, 캠페인유형, 성과관리기간포함, 진척현황
        this.lv_ReturnStr = [this.lv_CustNm, this.lv_CMChnlCustScCdVO.find(e => e.value === this.lv_ChnlCustScCd).text, 
                            this.lv_CMCmpgnTypVO.find(e => e.value === this.lv_CmpgnTypCd).text,
                            this.lv_CMCmpgnOptValVO.find(e => e.value === this.lv_ResuMngPrdIcluYn).text, 
                            this.lv_CMCmpgnProgPnstaCdVO.find(e => e.value === this.lv_CmpgnProgPnstaCd).text]
      }

      this.$emit('onPopupSearch', {rtnData: t_RtnData, srchOpt: this.lv_ReturnStr, isInit: isInit})
    }
  }
}
</script>