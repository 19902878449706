import { render, staticRenderFns } from "./MSPCM164P.vue?vue&type=template&id=59bfdfd0&scoped=true&"
import script from "./MSPCM164P.vue?vue&type=script&lang=js&"
export * from "./MSPCM164P.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59bfdfd0",
  null
  
)

export default component.exports