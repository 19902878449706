/*
 * 업무구분: 고객
 * 화 면 명: MSPCM164P
 * 화면설명: 캠페인 고객정보
 * 접근권한: 
 * 작 성 일: 2023.02.02
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="캠페인 고객정보" :show-title="true" type="subpage" @on-header-left-click="fn_HistoryBack"  :topButton="true">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-list-area pt15 pb20">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
         <div class="list-item w100 pb0">
          <div class="list-item__contents">
            <div class="list-item__contents__title fexJsCtTy1"> 
              <!-- 고객명 -->
              <!-- 고객카드 없음 240129 추가 -->
              <span class="name" style="display: flex;">
                <span class="txtSkip" @click.stop="fn_CustNmClick(lv_cMCmpgnCustInfoVO)">
                  {{ lv_cMCmpgnCustInfoVO.custNm }}
                </span>
                <span :class="!lv_cMCmpgnCustInfoVO.chnlCustId.trim() ? 'need-cs-card' : null" />
              </span>
              <!-- 고객카드 없음 240129 추가 -->
              <!-- 캠페인유형 -->
              <div class="bage-area" v-if="!$bizUtil.isEmpty(lv_cMCmpgnCustInfoVO.chnlCustScCdNm)">                                                
                <mo-badge class="badge-sample-badge lightblue sm" :text="lv_cMCmpgnCustInfoVO.chnlCustScCdNm" shape="status">{{lv_cMCmpgnCustInfoVO.chnlCustScCdNm}}</mo-badge> 
              </div>
            </div>
            <div class="list-item__contents__info">
              <div class="info_box">
                <span>{{lv_cMCmpgnCustInfoVO.dob2}}</span><em>|</em><span>{{lv_cMCmpgnCustInfoVO.sxdsCdNm}}</span><em>|</em><span>{{lv_cMCmpgnCustInfoVO.celno}}</span>
              </div>
            </div>
          </div>
         </div> 
        </ur-box-container>
      </ur-box-container>
      <mo-tab-box default-idx="1" class="ns-move-tab-box tween">
        <mo-tab-label idx="1" @click="fn_ChangeIdx('1')">캠페인</mo-tab-label>
        <mo-tab-label idx="2" @click="fn_ChangeIdx('2')">보장내역</mo-tab-label>
      </mo-tab-box>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <!-- 상세정보 리스트 -->
      <MSPCM165D v-if="lv_index === '1'" :cMCmpgnCustInfoVO="lv_cMCmpgnCustInfoVO" @fn_MoveMSPCM160M="fn_MoveMSPCM160M"/>
      <MSPCM166D v-if="lv_index === '2'" :cMCmpgnCustInfoVO="lv_cMCmpgnCustInfoVO"/>
      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <ur-box-container alignV="start" componentid="ur_box_container_033" direction="column">
        <div class="ns-height40"></div>
      </ur-box-container>
      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 //-->
    </ur-box-container>

    <!-- 개인정보 이용 및 활용 동의 팝업 -->
    <mo-bottom-sheet ref="bottomSheet_CustInfo" class="ns-bottom-sheet ns-style3">
      <template v-slot:title>고객카드 생성</template>
      <div class="customer-info-txt pt0">
        <strong class="name">{{ pObjmsg.custNm }}</strong>
        <p class="info">생년월일 
          <span class="birth">{{ $bizUtil.dateDashFormat(pObjmsg.dob) }}</span><em>|</em>
          <span>{{ pObjmsg.sxdsCdNm }}</span>
        </p>
        <p class="ment">
          등록된 고객이 아닙니다.<br>
          '고객카드 생성'버튼을 클릭하시면<br>
          고객카드가 생성됩니다.<br>
          (단, 필수컨설팅, 마케팅동의는 별도 수집 필요)
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_InitBottomSheet()">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Btn_I_OnClick()">고객카드 생성</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
  
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPCM165D from '@/ui/cm/MSPCM165D' // 캠페인 디비전
import MSPCM166D from '@/ui/cm/MSPCM166D' // 보장내역 디비전

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM164P",
  screenId: "MSPCM164P",
  props: {
    pCMCmpgnCustInfoVO: {
      type: Object,
      default : null
    }, // 캠페인 리스트에서 사용자가 선택한 항목 Object
    pOrgData160M: {
      type: Object,
      default : null
    }
  },

  components: {
    MSPCM165D,
    MSPCM166D
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      userInfo: {},
      lv_cMCmpgnCustInfoVO: {},
      lv_pOrgData160M: {},
      lv_index: '1',
      lv_dob: '',
      lv_cnsltNo: '',
      isMngr: false,
      isNewCust: false,
      // 서비스호출 파라미터(리드상태변경)
      lv_inputParamS: {
        cMCmpgnCustInfoVO: []
      },
      // 고객카드 생성 변수
      pObjmsg: { // 고객카드 생성 Obj
        cmpgnId: '',        // 캠페인ID(EXTERNAL_ID)
        cmpgnTypCd: '',     // 캠페인유형코드(QUAL_LEVEL_MAN)
        chnlCustId: '',     // 채널고객ID(ZA_AGT_FILE_ID)
        guid: '',           // GUID(GUID)
        cmpgnStatCd: '',    // 캠페인상태코드(ESTAT)
        custId: '',         // 고객ID(PARTNER)
        cnsltNo: '',        // 컨설턴트번호(ZA_CHNL_ID)
        dob: '',            // 생년월일
        custNm: '',         // 고객명
        sxdsCdNm: '',       // 성별
      },
      msgCntnt: '', // 서비스 호출결과 메시지
    };
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created () {
    let lv_Vm = this
    lv_Vm.lv_cMCmpgnCustInfoVO = lv_Vm.$props.pCMCmpgnCustInfoVO
    lv_Vm.lv_pOrgData160M = lv_Vm.$props.pOrgData160M
    lv_Vm.userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }
    if(this.lv_pOrgData160M.cnslt === undefined || this.lv_pOrgData160M.cnslt === null || this.lv_pOrgData160M.cnslt === ''){
      this.lv_cnsltNo = this.userInfo.userId
    }else{
      this.lv_cnsltNo = this.lv_pOrgData160M.cnslt.key
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_ChangeIdx
     * 설명       : 캠페인 / 보장내역 Tab Change
     ******************************************************************************/
    fn_ChangeIdx (str) {
      if (str === '1') {
        this.lv_index = '1'
      } else {
        this.lv_index = '2'
      }
    },

    /******************************************************************************
     * Function명  : fn_HistoryBack
     * 설명        : 뒤로가기 (history back)
     ******************************************************************************/
    fn_HistoryBack () {
      this.$emit('onPopupClose', this.isNewCust);
    },

    /******************************************************************************
     * Function명 : fn_ChangeIdx
     * 설명       : 캠페인 / 보장내역 Tab Change
     ******************************************************************************/
    fn_MoveMSPCM160M (param) {
      this.$emit('onPopupSearch', {cmpgnId: param.cmpgnId})
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheet_CustInfo !== undefined) {
          lv_Vm.$refs.bottomSheet_CustInfo.close()
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_CustNmClick
    * 설명       : 고객명 눌렀을 때 수행되는 함수
    ******************************************************************************/
    fn_CustNmClick (custInfo) {
      if (custInfo.chnlCustId.trim() === '') {
        // 채널고객ID가 없을 경우 공통이 아닌 화면별 고객등록동의 팝업 표시
        this.fn_OpenInsCustInfo(custInfo)
      } else {
        // 2020.06.09
        if (custInfo.cmpgnStatCd === 'E0001') { // ESTAE 값이 E0001 일때만 0158 호출 요청 (E0002로 업데이트)
          this.fn_DataSetS(custInfo)
          this.fn_updCmpgnObjExclu(custInfo)
        }
        // 네임UI 오픈
        this.fn_OpenCustInfoBs(custInfo)
      }
    },

    /******************************************************************************
     * Function명 : fn_Btn_I_OnClick
     * 설명       : 고객카드생성 확인 버튼
     ******************************************************************************/
    fn_Btn_I_OnClick () {
      // 지점장 권한일 경우 고객카드생성 불가
      if (this.isMngr !== true) {
        window.vue.getStore('progress').dispatch('FULL')
        this.fn_insCmpgnCustInfo()
      }
    },

    /******************************************************************************
    * Function명 : fn_insCmpgnCustInfo
    * 설명       : 캠페인 신규고객카드 생성 (F1CRM0159)
    *
   ******************************************************************************/
    fn_insCmpgnCustInfo () {
      const trnstId = 'txTSSCM53I2'
      const auth = 'I'
      let lv_Vm = this
      let confirm = this.getStore('confirm')
      let pParams = this.pObjmsg

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
        // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body

            if (responseList.msgScCd === 'S') { // 성공메시지
              lv_Vm.msgCntnt = responseList.msgCntnt
              lv_Vm.isNewCust = true
              // 바텀시트 초기화
              lv_Vm.fn_InitBottomSheet()
              // 네임UI 오픈
              let custInfo = {
                chnlCustId: responseList.cMCmpgnCustInfoVO[0].chnlCustId,
                custNm: ''
              }
              lv_Vm.fn_OpenCustInfoBs(custInfo)
            } else { // 실패메시지
              lv_Vm.msgCntnt = responseList.msgCntnt
              // 메시지 팝업 처리
              if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.msgCntnt)) {
                confirm.dispatch('ADD', lv_Vm.msgCntnt)
                lv_Vm.msgCntnt = '' // 초기화
              }
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_OpenInsCustInfo
     * 설명       : 고객카드 생성 팝업
     ******************************************************************************/
    fn_OpenInsCustInfo (custInfo) {
      this.pObjmsg = {
        cmpgnId: custInfo.cmpgnId, // 캠페인ID(EXTERNAL_ID)
        cmpgnTypCd: custInfo.cmpgnTypCd, // 캠페인유형코드(QUAL_LEVEL_MAN)
        chnlCustId: custInfo.chnlCustId, // 채널고객ID(ZA_AGT_FILE_ID)
        guid: custInfo.guid, // GUID(GUID)
        cmpgnStatCd: custInfo.cmpgnStatCd, // 캠페인상태코드(ESTAT)
        custId: custInfo.custId, // 고객ID(PARTNER)
        cnsltNo: this.lv_cnsltNo, // 컨설턴트번호(ZA_CHNL_ID)
        dob: custInfo.dob, // 생년월일
        custNm: custInfo.custNm, // 고객명
        sxdsCdNm: custInfo.sxdsCdNm, // 성별
      }

      // 고객카드생성 바텀시트 오픈
      this.$refs.bottomSheet_CustInfo.open()
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : this.$options.screenId, //화면ID+
        cnsltNo : this.lv_cnsltNo,
        custNm : item.custNm
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
      * Function명 : fn_DataSetS
      * 설명       : 캠페인고객 리드상태변경 처리 전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetS (custInfo) {
      this.lv_inputParamS = {
        cMCmpgnCustInfoVO: []
      }
      let saveObj = {}
      saveObj.guid = custInfo.guid // GUID
      saveObj.incluYn = 'X' // 포함여부 (REJECTED)
      saveObj.cmpgnStatCd = custInfo.cmpgnStatCd // 캠페인상태코드 (ESTAT)
      saveObj.zzPriority = custInfo.zzPriority // 우선순위 (IMPORTANCE)
      saveObj.cmpgnId = custInfo.cmpgnId // 캠페인ID (EXTERNAL_ID)
      saveObj.chnlCustId = custInfo.chnlCustId // 채널고객ID (ZA_AGT_FILE_ID)
      saveObj.cmpgnObjId = custInfo.cmpgnObjId // 캠페인대상ID (OBJECT_ID)
      saveObj.cmpgnTypCd = custInfo.cmpgnTypCd // 캠페인유형코드 (QUAL_LEVEL_MAN)
      saveObj.cmpgnTyp = custInfo.cmpgnTyp // 캠페인유형 (CAMP_TYPE)
      saveObj.cmpgnGoalCntnt = custInfo.cmpgnGoalCntnt // 캠페인목표내용 (OBJECTIVE)
      saveObj.cmpgnPrfmcMthCd = custInfo.cmpgnPrfmcMthCd // 캠페인수행방법코드 (TACTICS)

      this.lv_inputParamS.cMCmpgnCustInfoVO.push(saveObj)
    },

    /******************************************************************************
    * Function명 : fn_updCmpgnObjExclu
    * 설명       : 캠페인고객 리드상태변경
    *
   ******************************************************************************/
    fn_updCmpgnObjExclu (custInfo) {
      const trnstId = 'txTSSCM53D2' // F1CRM0158
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      this.isLoading = true

      let pParams = this.lv_inputParamS
      let sFailName = ''

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body

            if (responseList.msgScCd === 'S') { // 성공메시지
              lv_Vm.msgCntnt = responseList.msgCntnt
            } else { // 고객별 실패메시지
              if (responseList.cMCmpgnCustInfoVO.length !== 0) {
                for (let i = 0; i < responseList.cMCmpgnCustInfoVO.length; i++) {
                  if (sFailName === '') {
                    sFailName = responseList.cMCmpgnCustInfoVO[i].msgCntnt
                  } else {
                    sFailName = sFailName + ' : ' + responseList.cMCmpgnCustInfoVO[i].msgCntnt
                  }
                }
                lv_Vm.msgCntnt = sFailName
              }
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
  }
};
</script>
<style scoped>
</style>