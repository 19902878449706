/*
 * 업무구분: 고객
 * 화 면 명: MSPCM165D
 * 화면설명: 캠페인 상세정보 - 캠페인(디비전)
 * 접근권한: 
 * 작 성 일: 2023.02.02
 * 작 성 자: 김연숙
 */
<template>
  <ur-box-container direction="column" alignV="start">
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
      <div class="info-box pt30 pb20">
        <b class="underline fs19rem" @click="fn_SelCmpgnDtl(cMCmpgnCustInfoVO)">{{cMCmpgnCustInfoVO.cmpgnNm}}</b>
        <p class="mt10 mb0 fs14rem">{{$commonUtil.dateDashFormat(cMCmpgnCustInfoVO.strYmd)}} ~ {{$commonUtil.dateDashFormat(cMCmpgnCustInfoVO.endYmd)}}</p>
      </div>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="" direction="column" class="msp-detail-lst">  
      <ul class="lst pt16 bd-T-Ty1">
        <li>
          <span class="tit">부가정보1</span>
          <span class="txt">{{cMCmpgnCustInfoVO.adtnInfoCntnt01}}</span>
        </li>
        <li>
          <span class="tit">부가정보2</span>
          <span class="txt">{{cMCmpgnCustInfoVO.adtnInfoCntnt02}}</span>
        </li>
        <li>
          <span class="tit">부가정보3</span>
          <span class="txt">{{cMCmpgnCustInfoVO.adtnInfoCntnt03}}</span>
        </li>
        <li>
          <span class="tit">부가정보4</span>
          <span class="txt">{{cMCmpgnCustInfoVO.adtnInfoCntnt04}}</span>
        </li>
        <li>
          <span class="tit">담당부서명</span>
          <span class="txt">{{cMCmpgnCustInfoVO.chrgeDeptNm}}</span>
        </li>
        <li>
          <span class="tit">필수컨설팅동의</span>
          <span class="txt">{{lv_mndtCnstgCnsntRmdrDds}}</span>
        </li>
        <li>
          <span class="tit">컨설팅</span>
          <span class="txt">{{$commonUtil.dateDashFormat(cMCmpgnCustInfoVO.cnstgYmd)}}</span>
        </li>
        <li>
          <span class="tit">가입설계</span>
          <span class="txt">{{$commonUtil.dateDashFormat(cMCmpgnCustInfoVO.entplYmd)}}</span>
        </li>
        <li>
          <span class="tit">최근터치일</span>
          <span class="txt">{{cMCmpgnCustInfoVO.rntTuchYmd2}}</span>
        </li>
        <li>
          <span class="tit">신계약</span>
          <span class="txt">{{$commonUtil.dateDashFormat(cMCmpgnCustInfoVO.ncontYmd)}}</span>
        </li>
        <li>
          <span class="tit">유지건</span>
          <span class="txt">{{cMCmpgnCustInfoVO.ukepCnt}}</span>
        </li>
        <li>
          <span class="tit">총보험료(천원)</span>
          <span class="txt">{{cMCmpgnCustInfoVO.totPrm}}</span>
        </li>
        <li>
          <span class="tit">진척현황</span>
          <span class="txt">{{cMCmpgnCustInfoVO.cmpgnProgPnstaCdNm}}</span>
        </li>
        <!-- <li>
          <span class="tit">기간</span>
          <span class="txt">{{cMCmpgnCustInfoVO.strYmd2 + ' ~ ' + cMCmpgnCustInfoVO.endYmd2}}</span>
        </li> -->
      </ul>
    </ur-box-container>

    <mo-bottom-sheet ref="bottomSheetDtl" :close-btn="false" class="ns-bottom-sheet sheet-type-1 closebtn">
        <template v-slot:title>
          <!-- 캠페인명 / 기간 -->
          <span>{{lv_selectedData.name}}</span>
          <div class="sheet-date-wrap">
            <span>{{lv_selectedData.date1}}</span>~<span>{{lv_selectedData.date2}}</span>&nbsp;<span v-if="lv_selectedData.date3 !== ''">(D-{{lv_selectedData.date3}})</span>
          </div>
          <div class="ns-btn-close" @click="fn_CtrlBottomSheet('close')" name="닫기"></div>
        </template>
        <!-- 상세, 추가정보 -->
        <div class="content-area pl0 pr0">
          <div class="pl24 pr24">
            <ul class="bullet-type-1">
              <span v-html="lv_selectedData.cmpgnCntnt"/>
            </ul>
          </div>
          <div class="msp-detail-lst type-1 pb10">
            <div class="pb10 pt10 fwm">추가정보</div>
            <ul class="lst pt16 bd-T-Ty1">
              <li>
                <span class="tit">골프장</span>
                <span class="txt">{{lv_selectedData.prgPlcCdNm}}</span>
              </li>
              <li>
                <span class="tit">행사장소명/제휴처명</span>
                <span class="txt">{{lv_selectedData.cmpgnPlcNm}}</span>
              </li>
              <li>
                <span class="tit">강사유형</span>
                <span class="txt">{{lv_selectedData.lectrTypCdNm}}</span>
              </li>
              <li>
                <span class="tit">컨설턴트참석여부</span>
                <span class="txt">{{lv_selectedData.cnsltAtdncCdNm}}</span>
              </li>
              <li>
                <span class="tit">인원수(세미나T/O)</span>
                <span class="txt">{{lv_selectedData.cmpgnPsbNprsnFgr}}</span>
              </li>
              <li>
                <span class="tit">인원수(세미나신청)</span>
                <span class="txt">{{lv_selectedData.cmpgnAplNprsnFgr}}</span>
              </li>
            </ul>
          </div>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round white mr5" @click="fn_CtrlBottomSheet('cmpgn')">캠페인 고객보기</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue ml5" @click="fn_CtrlBottomSheet('close')">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
  </ur-box-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM165D",
    screenId: "MSPCM165D",
    props: ['cMCmpgnCustInfoVO'],

    components: {
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        userInfo: {},
        lv_selectedData: {}, // 선택한 캠페인 item
        lv_mndtCnstgCnsntRmdrDds: 'N',  // 필수컨설팅동의
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo

      // 데이터 세팅
      this.fn_Init()
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*****************************************
       * Function명  : fn_Init
       * 설명        : 모화면으로부터 넘겨받은 데이터 가공
       *****************************************/
      fn_Init () {
        let lv_Vm = this

        if (lv_Vm.cMCmpgnCustInfoVO.mndtCnstgCnsntRmdrDds !== '') {
          let item = Number(lv_Vm.cMCmpgnCustInfoVO.mndtCnstgCnsntRmdrDds)
          lv_Vm.lv_mndtCnstgCnsntRmdrDds = (item === 0)? 'N': (item < 100)? 'D-' + item: (item >= 100)? 'D-99+': 'N'
        }
      },

      /******************************************************************************
       * Function명  : fn_SelCmpgnDtl
       * 설명        : 선택한 캠페인 상세내용 조회 및 바텀시트 호출
       ******************************************************************************/
      fn_SelCmpgnDtl (item) {
        let lv_Vm = this
        const trnstId = 'txTSSCM53S5' 
        const auth = 'S'
        let pParams = {}
        let dateToday = moment(new Date()).format('YYYYMMDD')
        let sfinishDate = moment(item.endYmd2).format('YYYYMMDD')

        pParams.cmpgnClsfKey = item.cmpgnId// 캠페인 GUID (OBJKEY_A) / 캠페인ID (EXTERNAL_ID)
        
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null && response.body !== '') {
              if ((response.body.msgScCd === 'S' || response.body.msgScCd === null) && response.body.cMCmpgnDtlVO !== null && response.body.cMCmpgnDtlVO.length > 0 ) {
                let responseList = response.body.cMCmpgnDtlVO
                lv_Vm.lv_selectedData = {}
                lv_Vm.lv_selectedData.cmpgnCntnt = responseList[0].cmpgnCntnt.replace(/(?:\r\n|\r|\n)/g, '<br />')
                lv_Vm.lv_selectedData.date1 = item.strYmd2.substr(5)
                lv_Vm.lv_selectedData.date2 = item.endYmd2.substr(5)
                lv_Vm.lv_selectedData.cmpgnId = item.cmpgnId
                lv_Vm.lv_selectedData.name = item.cmpgnNm
                lv_Vm.lv_selectedData.prgPlcCdNm = responseList[0].prgPlcCdNm // 진행장소코드명 (ZZGOLF_PLACEC)
                lv_Vm.lv_selectedData.cmpgnPlcNm = responseList[0].cmpgnPlcNm // 캠페인장소명 (ZZPLACE_NAME)
                lv_Vm.lv_selectedData.lectrTypCdNm = responseList[0].lectrTypCdNm // 강사유형코드명 (ZZLECTR_TYPEC)
                lv_Vm.lv_selectedData.cnsltAtdncCdNm = responseList[0].cnsltAtdncCdNm // 컨설턴트참석코드명 (ZZFC_ATTENDC)
                lv_Vm.lv_selectedData.cmpgnPsbNprsnFgr = responseList[0].cmpgnPsbNprsnFgr // 캠페인가능인원수 (ZZEXPT_NUMBER)
                lv_Vm.lv_selectedData.cmpgnAplNprsnFgr = responseList[0].cmpgnAplNprsnFgr // 캠페인신청인원수 (ZA_TOT_CNT)

                // 오늘이 종료일 전일 경우
                if (Number(sfinishDate) > Number(dateToday)) lv_Vm.lv_selectedData.date3 = Number(sfinishDate) - Number(dateToday)
                else lv_Vm.lv_selectedData.date3 = ''

                lv_Vm.$nextTick(() => {
                  // 바텀시트 오픈
                  lv_Vm.fn_CtrlBottomSheet('open')
                })
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /******************************************************************************
       * Function명  : fn_CtrlBottomSheet
       * 설명        : 바텀시트 컨트롤
       ******************************************************************************/
      fn_CtrlBottomSheet (str) {
        let lv_Vm = this

        if (lv_Vm.$refs !== undefined) {
          if (lv_Vm.$refs.bottomSheetDtl !== undefined) {
            if (str === 'open') lv_Vm.$refs.bottomSheetDtl.open()
            else if (str === 'cmpgn') {
              // 선택한 캠페인 고객 리스트 호출
              lv_Vm.fn_MoveMSPCM160M()
            }
            else lv_Vm.$refs.bottomSheetDtl.close()
          }
        }
      },

      /******************************************************************************
       * Function명  : fn_MoveMSPCM160M
       * 설명        : 캠페인고객 메인화면으로 이동
       ******************************************************************************/
      fn_MoveMSPCM160M () {
        this.$emit('fn_MoveMSPCM160M', {cmpgnId: this.cMCmpgnCustInfoVO.cmpgnId})
      }
    }
  };
</script>
<style scoped>
</style>