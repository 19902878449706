/*
 * 업무구분: 고객
 * 화 면 명: MSPCM166D
 * 화면설명: 캠페인 상세정보 - 보장내역(디비전)
 * 접근권한: 
 * 작 성 일: 2023.02.02
 * 작 성 자: 김연숙
 */
<template>
  <ur-box-container direction="column" alignV="start">
    <ur-box-container alignV="start" componentid="" direction="column" class="msp-detail-lst type-1 mt30">  
    <ul class="lst">
      <li>
        <span class="tit">사망보장 일사 (백만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.genDthCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">사망보장 재사 (백만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.dstrDthCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">노후준비 보험료 (만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.anutMntPadPrm}}</span>
      </li>
      <li>
        <span class="tit">노후준비 기납입 (만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.alpadAnutPrm}}</span>
      </li>
      <li>
        <span class="tit">CI진단 (만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.ciDignCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">의료비 보장 (만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.diseHspzRldmCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">진단보장 암 (백만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.cncrDignCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">진단보장 2대 질병 (백만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.cbapDignCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">중대한 수술 (백만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.ciSurgCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">입원 질병 (만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.diseHspzCovrAmt}}</span>
      </li>
      <li>
        <span class="tit">입원 암 (만원)</span>
        <span class="txt">{{cMCmpgnCustInfoVO.cncrHspzCovrAmt}}</span>
      </li>
    </ul>
  </ur-box-container>
</ur-box-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM166D",
    screenId: "MSPCM166D",
    props: ['cMCmpgnCustInfoVO'],

    components: {
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        userInfo: {},
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*****************************************
       * Function명  : fn_HistoryBack
       * 설명        : 뒤로가기 (history back)
       *****************************************/
      fn_HistoryBack () {
        this.$emit('onPopupClose');
      },
    }
  };
</script>
<style scoped>
</style>